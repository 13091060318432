<template>
  <div>
    <div class="patient-charts-container">
      <div v-if="state === State.Loading" class="spinner-container">
        <b-spinner
          style="color: #8768ad; width: 100px; height: 100px"
        ></b-spinner>
      </div>
      <div v-else-if="state === State.NoPatch" class="status-message-container">
        {{ $t("patient.noPatch") }}
      </div>
      <div
        v-else-if="state === State.NoActivePatch"
        class="status-message-container"
      >
        {{ $t("patient.noActivePatch") }}
      </div>
      <div v-else-if="state === State.HasActivePatch">
        <ChartContainer
          id="patient-availability"
          :fetch-status="fetchTempStatus"
          :chart-data="availabilityData"
          chart-type="availability"
        >
          <template #header>
            <AvailabilityHeader
              :value="dataWindow"
              @input="updateWindow"
              @download="exportData"
            />
          </template>
          <template #chart>
            <AvailabilityChart
              :key="`${lowerBound}-${upperBound}-availability`"
              chart-type="availability"
              :acute="patient.acute"
              :acute-mode="patient.acutemode"
              :upper-bound="upperBound"
              :lower-bound="lowerBound"
              :chart-data="availabilityData"
              :click-handler="handleEngineeringLink"
              chart-id="patient-data-chart"
            />
          </template>
        </ChartContainer>


        <ChartContainer
          v-if="isPotassiumSupported()"
          :fetch-status="fetchPotassiumStatus"
          :chart-data="potassiumData"
          chart-type="potassium"
        >
          <template #header>
            <chart-header
              chart-type="potassium"
              :chart-data="potassiumData"
              :ordinal="showOrdinalPotassium"

            >
              <img
                id="chart-header-icon-potassium"
                slot="image"
                class="header-icon"
                :alt="$t('patient.chartHeaderIcon')"
                :src="potassiumChartIcon"
              />
            </chart-header>
          </template>
          <template #chart>
            <PotassiumChart
              :key="`${lowerBound}-${upperBound}-potassium`"
              :acute="patient.acute"
              :acute-mode="patient.acutemode"
              :upper-bound="upperBound"
              :lower-bound="lowerBound"
              :events="dialysisEvents"
              :chart-data="potassiumData"
              :click-handler="handleEngineeringLink"
              chart-id="patient-potassium-chart"
              :ordinal="showOrdinalPotassium"
            />
          </template>
        </ChartContainer>

        <ChartContainer v-if="isHgbSupported()" :fetch-status="fetchHGBStatus" :chart-data="hgbData" chart-type="hgb">
          <template #header>
            <chart-header
              chart-type="hgb"
              :chart-data="hgbData"
              :avg24h-value="avgHgbDisplay"

            >
              <img
                id="chart-header-icon-hgb"
                slot="image"
                class="header-icon"
                :alt="$t('patient.chartHeaderIcon')"
                :src="hemoglobinChartIcon"
              />
            </chart-header>
          </template>
          <template #chart>
            <HHChart
              :key="`${lowerBound}-${upperBound}-hgb`"
              chart-type="hgb"
              :acute="patient.acute"
              :acute-mode="patient.acutemode"
              :upper-bound="upperBound"
              :lower-bound="lowerBound"
              :events="dialysisEvents"
              :chart-data="hgbData"
              :avg24h-value="avgHgb"
              :click-handler="handleEngineeringLink"
              chart-id="patient-hgb-chart"
            />
          </template>
        </ChartContainer>


          <ChartContainer v-if="isHctSupported" :fetch-status="fetchHCTStatus" :chart-data="hctData" chart-type="hct">
            <template #header>
              <chart-header
                chart-type="hct"
                :chart-data="hctData"
                :avg24h-value="avgHctDisplay"

              >
                <img
                  id="chart-header-icon-hct"
                  slot="image"
                  class="header-icon"
                  :alt="$t('patient.chartHeaderIcon')"
                  :src="hematocritChartIcon"
                />
              </chart-header>
            </template>
            <template #chart>
              <HHChart
                :key="`${lowerBound}-${upperBound}-hct`"
                chart-type="hct"
                :acute="patient.acute"
                :acute-mode="patient.acutemode"
                :upper-bound="upperBound"
                :lower-bound="lowerBound"
                :events="dialysisEvents"
                :chart-data="hctData"
                :avg24h-value="avgHct"
                :click-handler="handleEngineeringLink"
                chart-id="patient-hct-chart"
              />
            </template>
          </ChartContainer>

        <div v-if="isRBVSupportedClinic()" class="rbv-container">
          <div class="tw-flex tw-justify-between">
            <section-header class="pr-3"
                            :header-text="$t('patient.detailsHeaderRelativeBloodVolume')"
            />
            <h4 class="tw-bg-[#826cab] tw-rounded-bl-lg tw-p-1 tw-text-white tw-mt-[-8px] tw-ml-[-8px] tw-mr-[-8px] tw-ml-[-8px]">BETA</h4>
          </div>
          <RelativeBloodVolume :data="hctData" :lower-bound="lowerBound" :upper-bound="upperBound" />
        </div>

        <ChartContainer v-if="showSpo2()" :fetch-status="fetchSPO2Status" chart-type="spo2" :chart-data="spo2Data">
          <template #header>
            <chart-header
              chart-type="spo2"
              :chart-data="spo2Data"
            />
          </template>
          <template #chart>
            <DotChart
              :key="`${lowerBound}-${upperBound}-spo2`"
              chart-type="spo2"
              :acute="patient.acute"
              :acute-mode="patient.acutemode"
              :upper-bound="upperBound"
              :lower-bound="lowerBound"
              :chart-data="spo2Data"
              :events="dialysisEvents"
              :click-handler="handleEngineeringLink"
              chart-id="patient-spo2-chart"
            />
          </template>
        </ChartContainer>

          <ChartContainer
            v-if="isLungSoundsSupported()"
            :fetch-status="fetchLungStatus"
            :chart-data="lungSoundsData"
            chart-type="auscultation"
          >
            <template #header>
              <chart-header
                chart-type="auscultation"
                :chart-data="lungSoundsData"

              />
            </template>
            <template #chart>
              <AuscultationChart
                :key="`${lowerBound}-${upperBound}-auscultation`"
                chart-type="auscultation"
                :acute="patient.acute"
                :acute-mode="patient.acutemode"
                :upper-bound="upperBound"
                :lower-bound="lowerBound"
                :chart-data="lungSoundsData"
                :click-handler="handleAuscultationHit"
                chart-id="'patient-lung-sounds-chart"
              />
            </template>
          </ChartContainer>

          <ChartContainer v-if="isHeartRateSupported()" :fetch-status="fetchHRStatus" :chart-data="heartRateData" chart-type="hr">
            <template #header>
              <chart-header
                chart-type="hr"
                :chart-data="heartRateData"
              />
            </template>
            <template #chart>
              <DotChart
                :key="`${lowerBound}-${upperBound}-hr`"
                chart-type="hr"
                :acute="patient.acute"
                :acute-mode="patient.acutemode"
                :upper-bound="upperBound"
                :lower-bound="lowerBound"
                :events="dialysisEvents"
                :chart-data="heartRateData"
                :click-handler="handleEngineeringLink"
                chart-id="patient-heart-rate-chart"
              />
            </template>
          </ChartContainer>

          <ChartContainer
            :fetch-status="fetchTempStatus"
            :chart-data="temperatureData"
            chart-type="temperature"
          >
            <template #header><chart-header
              chart-type="temperature"
              :chart-data="temperatureData"

            /></template>
            <template #chart>
              <TempChart
                :key="`${lowerBound}-${upperBound}-temp`"
                chart-type="temperature"
                :acute="patient.acute"
                :acute-mode="patient.acutemode"
                :upper-bound="upperBound"
                :lower-bound="lowerBound"
                :events="dialysisEvents"
                :chart-data="temperatureData"
                :click-handler="handleEngineeringLink"
                chart-id="temperature-chart"
              />

            </template>
          </ChartContainer>
      </div>
    </div>

    <audio-file-list-modal
      modal-id="lung-sounds-file-list-modal"
      :patientid="patientIdParameter"
      :start="auscultationModalStart"
      :stop="auscultationModalStop"
      :show-engineering-links="isSupportApp()"
      @handle-engineering-link="handleEngineeringLink"
    />
  </div>
</template>

<script lang="ts">
import HHChart from "@/shared/components/Charts/HHChart.vue";
import DotChart from "@/shared/components/Charts/DotChart.vue";
import TempChart from "@/shared/components/Charts/TempChart.vue";
import PotassiumChart from "@/shared/components/Charts/PotassiumChart.vue";
import AuscultationChart from "@/shared/components/Charts/AuscultationChart.vue";
import AvailabilityChart from "@/shared/components/Charts/AvailabilityChart.vue";
import ChartContainer from "@/shared/components/Charts/ChartContainer.vue";
import AvailabilityHeader from "@/shared/components/AvailabilityHeader.vue";
import { DataWindow } from "@/shared/utils/dataWindow";
import { Patient, Clinic } from "@/shared/api-client/api";
import {
  getPotassiumChartHeaderIcon,
  getHctChartHeaderIcon,
  getHgbChartHeaderIcon,
  round,
  groupBy
} from "@/shared/utils";
import RelativeBloodVolume from "@/shared/components/RelativeBloodVolume.vue";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import { apiStatus } from "@/shared/constants";

const State = Object.freeze({
  Loading: 1,
  NoPatch: 2,
  NoActivePatch: 3,
  HasActivePatch: 4,
});

export default {
  name: "PatientCharts",
  components: {
    RelativeBloodVolume,
    AvailabilityHeader,
    HHChart,
    DotChart,
    TempChart,
    PotassiumChart,
    AuscultationChart,
    AvailabilityChart,
    ChartContainer,
    SectionHeader
  },
  props: {
    patient: {
      type: Object as () => Patient,
      required: true,
    },
    clinic: {
      type: Object as () => Clinic,
      required: true,
    },
    supportInfo: {
      type: Object,
    },
  },
  data() {
    return {
      dataWindow: null,
      State,
      state: State.Loading,
      // API STATUSES
      fetchTempStatus: apiStatus.IDLE,
      fetchPotassiumStatus: apiStatus.IDLE,
      fetchHRStatus: apiStatus.IDLE,
      fetchLungStatus: apiStatus.IDLE,
      fetchHCTStatus: apiStatus.IDLE,
      fetchHGBStatus: apiStatus.IDLE,
      fetchSPO2Status: apiStatus.IDLE,
      fetchDialysisEventsStatus: apiStatus.IDLE,

      // Patient Data
      temperatureData: null,
      potassiumData: null,
      heartRateData: null,
      lungSoundsData: null,
      hctData: null,
      hgbData: null,
      spo2Data: null,
      dialysisEvents: null,
      // Lung Sounds Modal
      auscultationModalStart: null,
      auscultationModalStop: null,
    };
  },
  computed: {
    isCalfLocation() {
      const patchLocations = this.clinic.patchlocations;

      if (patchLocations) {
        for (let item of patchLocations) {
          if ((item.id === this.patient.patchplacement) && item.location_type === 'calf') {
            return true
          }
        }
      }

      return false;
    },
    potassiumChartIcon() {
      return getPotassiumChartHeaderIcon(
        this.clinic,
        this.patient,
        this.potassiumData?.data
      );
    },
    hematocritChartIcon() {
      return getHctChartHeaderIcon(
        this.clinic,
        this.patient,
        this.hctData?.data
      );
    },
    hemoglobinChartIcon() {
      return getHgbChartHeaderIcon(
        this.clinic,
        this.patient,
        this.hgbData?.data
      );
    },
    avgHctDisplay() {
      if (this.avgHct) {
        return `${this.dataWindow.windowNumberOfDays} Day Avg: ${round(this.avgHct, 1)}`;
      }
      return null
    },
    avgHgbDisplay() {
      if (this.avgHgb) {
        return `${this.dataWindow.windowNumberOfDays} Day Avg: ${round(this.avgHgb, 1)}`;
      }
      return null
    },
    avgHct() {
      return this.calculateAverage24Hours(this.hctData?.data);
    },
    avgHgb() {
      return this.calculateAverage24Hours(this.hgbData?.data);
    },
    showOrdinalPotassium() {
      return this.clinic?.ordinal_potassium;
    },
    hasMonitoringCompleted: function () {
      if (this.patient.monitoring_end === null) {
        return false;
      } else {
        return true;
      }
    },
    patientIdParameter: function () {
      return parseInt(this.$route.params.patientId, 10);
    },
    availabilityData: function () {
      // Temperature data is gathered at a fast polling rate (~every 5 min), while all
      // data is collected at the same time, at a much slower rather (~every 3 hours)
      // so using only patient temperature data as the basis for availability is reasonable
      // and will provide an accurate overview of data availability
      const data = this.temperatureData?.data
        ? this.temperatureData?.data?.map((tempEntry) => ({
            x: tempEntry.x,
            y: 1,
          }))
        : [];
      return {
        data,
        maxrange: 1,
        minrange: 0,
      };
    },
    lowerBound: function () {
      return this.dataWindow.windowStartTimeInSeconds;
    },
    upperBound: function () {
      return this.dataWindow.windowEndTimeInSeconds;
    },
  },
  watch: {
    patient: async function () {
      if (this.hasPatientData(this.patient)) {
        this.dataWindow = new DataWindow(
          this.patient.monitoring_start,
          this.patient.monitoring_end,
          this.$route.query?.start,
          this.$route.query?.end
        );
        await this.getPatientData(
          this.dataWindow.windowStartTimeInSeconds,
          this.dataWindow.windowEndTimeInSeconds
        );
        this.state = State.HasActivePatch;
      } else {
        if (this.patient.patches.length === 0) {
          this.state = State.NoPatch;
        } else if (!this.hasActivePatch(this.patient.patches)) {
          this.state = State.NoActivePatch;
        }
      }
      this.updateTableHeader()
      // Check to see if the refresh date and viewed should be updated
      this.updateRefreshAndViewed();
    },
  },
  methods: {
    getSPO2: async function (params) {
      try {
        this.fetchSPO2Status = apiStatus.LOADING;
        const response = await this.api.v1DataPatientidDatatypesGet(
          params.patientid,
          "spo2",
          params.start,
          params.stop
        );
        this.spo2Data = response.data.spo2;
        this.fetchSPO2Status = apiStatus.SUCCESS;
      } catch (error) {
        this.fetchSPO2Status = apiStatus.ERROR;
      }
    },
    updateTableHeader() {
      let number_notifiable_events = 0;
      const updateObj = {}

      if (this.isPotassiumSupported()) {
        number_notifiable_events += this.potassiumData?.total_notifiable_events || 0;
        if (this.showOrdinalPotassium) {
          updateObj['k_high_reads_percent'] = this.potassiumData?.high_reads_percent;
          updateObj['k_low_reads_percent'] = this.potassiumData?.low_reads_percent;
        } else {
          updateObj['k_abnormal_reads_percent'] = this.potassiumData?.abnormal_reads_percent;
        }
      }

      if (this.isHctSupported()) {
        number_notifiable_events += this.hctData?.total_notifiable_events || 0;
        updateObj['hct_high_reads_percent'] = this.hctData?.high_reads_percent
        updateObj['hct_low_reads_percent'] = this.hctData?.low_reads_percent
      }

      if (this.isHgbSupported()) {
        number_notifiable_events += this.hgbData?.total_notifiable_events || 0;
        updateObj['hgb_high_reads_percent'] = this.hgbData?.high_reads_percent;
        updateObj['hgb_low_reads_percent']=  this.hgbData?.low_reads_percent;
      }


      updateObj['number_notifiable_events'] = number_notifiable_events

      this.$emit('update-table-header', updateObj)
    },
    updateWindow(newWindow: DataWindow) {
      this.dataWindow = newWindow;
      // Update the URL with the new start and end times
      this.$router.replace({
        path: this.$route.path,
        query: {
          start: newWindow.windowStartTimeInSeconds,
          end: newWindow.windowEndTimeInSeconds
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });

      this.getPatientData(
        newWindow.windowStartTimeInSeconds,
        newWindow.windowEndTimeInSeconds
      ).then(() => {
        this.updateRefreshAndViewed()
        this.updateTableHeader()
      });
    },
    handleEngineeringLink(type, hit) {
      if (this.isSupportApp()) {
        const groupItems = hit?.target?.dataItem?.groupDataItems;

        if (groupItems) {
          if (groupItems.length === 1) {
            const epochS = groupItems[0]?.values?.dateX?.value / 1000;
            this.getAndRedirect(type, epochS);
          } else if (groupItems.length > 1) {
            this.$bus.emit(
              "show-general-error",
              this.$t("patient.multipleGroupItems")
            );
          }
        } else {
          if (hit?.target?.dataItem.get("valueX")) {
            const epochS = hit?.target?.dataItem.get("valueX") / 1000;
            this.getAndRedirect(type, epochS);
          } else {
            this.$bus.emit(
              "show-general-error",
              this.$t("patient.cannotRedirectGenericError")
            );
          }
        }
      }
    },
    handleAuscultationHit(hit) {
      const groupItems = hit?.target?.dataItem.get("originals");
      if (groupItems?.length > 0) {
        const unsortedData = groupItems.map((item) => {
          const epochS = item?.dataContext?.date / 1000;
          return this.lungSoundsData.data.find(
            (lungEntry) => lungEntry.x === epochS
          );
        });
        const sortedItems = unsortedData.sort((a, b) => {
          if (a.x < b.x) return -1;
          if (a.x > b.x) return 1;
          return 0;
        });
        this.auscultationModalStart = sortedItems[0].x;
        if (sortedItems.length > 1) {
          this.auscultationModalStop = sortedItems[sortedItems.length - 1].x;
        } else {
          this.auscultationModalStop = null;
        }
        this.$bvModal.show("lung-sounds-file-list-modal");
      }
    },
    hasActivePatch: function (patches) {
      for (let patch of patches) {
        if (patch.status === "active") return true;
      }
      return false;
    },
    hasPatientData: function (patient) {
      if (
        patient &&
        patient.monitoring_start &&
        typeof patient.monitoring_start === "number"
      ) {
        return true;
      }

      return false;
    },
    isHeartRateSupported: function () {
      if (this.isSupportApp() || this.hasHeartRateFeatureSupport(this.clinic)) {
        return true;
      }

      return false;
    },
    isPotassiumSupported: function () {
      if (this.isSupportApp() || this.hasPotassiumFeatureSupport(this.clinic)) {
        return true;
      }

      return false;
    },
    showSpo2() {
      if (!this.isCalfLocation) return false;
      if (!this.isSPO2Supported) return false;
      return this.hasSpo2FeatureSupport(this.clinic);
    },
    isRBVSupportedClinic: function () {
      return !!(this.hasRBVFeatureSupport(this.clinic) && this.isRBVSupported);
    },
    isLungSoundsSupported: function () {
      if (
        this.isSupportApp() ||
        (this.hasAuscultationFeatureSupport(this.clinic) && !this.isCalfLocation)
      ) {
        return true;
      }

      return false;
    },
    isHctSupported: function () {
      if (this.isSupportApp() || this.hasHctFeatureSupport(this.clinic)) {
        return true;
      }

      return false;
    },
    isHgbSupported: function () {
      if (this.isSupportApp() || this.hasHgbFeatureSupport(this.clinic)) {
        return true;
      }

      return false;
    },
    updateRefreshAndViewed: function () {
      if (this.isOnlyClinician(this.$store.getters.user)) {
        // If loading patient and patient data were successful, then
        // update the refresh date and updated the viewed.
        if (
          this.patient &&
          (!this.hasPatientData(this.patient) || this.temperatureData !== null)
        ) {
          // Updated the refresh date
          this.lastRefreshDate = new Date();

          this.api
            .v1PatientPatientidViewedPost(this.patientIdParameter)
            .then(() => {
              // Do nothing on success
            })
            .catch((error) => {
              this.logApiResponseError(
                "Patient Viewed",
                error,
                this.$t("patient.errorLoggingView")
              );
            });
        }
      }
    },
    getPatientData: async function (monitoringStart, monitoringEnd) {
      const getterPromises = [];

      const params = {
        patientid: this.patientIdParameter,
        start: monitoringStart,
        stop: monitoringEnd,
      };

      getterPromises.push(this.getTemperature(params));
      getterPromises.push(this.getDialysisEvents(params));

      if (this.isPotassiumSupported()) {
        // Load the Patient Heart Rate Data
        getterPromises.push(this.getPotassium(params));
      }

      if (this.isHeartRateSupported()) {
        // Load the Patient Heart Rate Data
        getterPromises.push(this.getHeartRate(params));
      }

      // we don't care about lung data when we are in acute mode
      if (this.isLungSoundsSupported()) {
        // Load the Patient Auscultation (Lung Sounds) data
        getterPromises.push(this.getAuscultation(params));
      }

      if (this.isHctSupported() || this.isHgbSupported()) {
        // Load the Patient Hct data
        getterPromises.push(this.getHGBAndHCT(params));
      }

      if (this.showSpo2()) {
        getterPromises.push(this.getSPO2(params))
      }


      // We want to wait for all gets to complete before updating refreshed and viewed and
      // establishing a websocket connection
      return Promise.allSettled(getterPromises);
    },
    getAuscultation: async function (params) {
      try {
        this.fetchLungStatus = apiStatus.LOADING;
        const response = await this.api.v1DataPatientidDatatypesGet(
          params.patientid,
          "audio",
          params.start,
          params.stop
        );
        this.lungSoundsData = response.data.audio;
        this.fetchLungStatus = apiStatus.SUCCESS
      } catch (error) {
        this.fetchLungStatus = apiStatus.ERROR;
      }
    },
    getHeartRate: async function (params) {
      try {
        this.fetchHRStatus = apiStatus.LOADING;
        const response = await this.api.v1DataPatientidDatatypesGet(
          params.patientid,
          "hr",
          params.start,
          params.stop
        );
        this.heartRateData = response.data.hr;
        this.fetchHRStatus = apiStatus.SUCCESS;
      } catch (error) {
        this.fetchHRStatus = apiStatus.ERROR;
      }
    },
    getPotassium: async function (params) {
      try {
        this.fetchPotassiumStatus = apiStatus.LOADING;
        const response = await this.api.v1DataPatientidDatatypesGet(
          params.patientid,
          "potassium",
          params.start,
          params.stop
        );
        this.potassiumData = response.data.potassium;
        this.fetchPotassiumStatus = apiStatus.SUCCESS;
      } catch (error) {
        this.fetchPotassiumStatus = apiStatus.ERROR;
      }
    },
    getDialysisEvents: async function (params) {
      try {
        this.fetchDialysisEventsStatus = apiStatus.LOADING
        const response = await this.api.v1PatientPatientIdDialysisEventsGet(
          params.patientid,
          params.start,
          params.stop
        );
        this.dialysisEvents = response.data?.events;
        this.fetchDialysisEventsStatus = apiStatus.SUCCESS
      } catch(err) {
        this.fetchDialysisEventsStatus = apiStatus.ERROR
        this.showApiResponseError(err, this.$t("patient.errorLoad"));
      }
    },
    getTemperature: async function (params) {
      try {
        this.fetchTempStatus = apiStatus.LOADING;
        const response = await this.api.v1DataPatientidDatatypesGet(
          params.patientid,
          "temperature",
          params.start,
          params.stop
        );

        let temperatureDataKey = "data_15min";
        if ("VITE_TEMPERATURE_DATA_KEY" in import.meta.env && typeof import.meta.env.VITE_TEMPERATURE_DATA_KEY === "string") {
          temperatureDataKey = import.meta.env.VITE_TEMPERATURE_DATA_KEY;
        }

        this.temperatureData = {
          ...response.data.temperature,
          data: response.data.temperature[temperatureDataKey],
        };
        this.fetchTempStatus = apiStatus.SUCCESS;
      } catch (error) {
        this.fetchTempStatus = apiStatus.ERROR;
      }
    },
    getHGBAndHCT: async function (params) {
      // Hgb data is just Hct divided by 3

      this.fetchHCTStatus = apiStatus.LOADING;
      this.fetchHGBStatus = apiStatus.LOADING;
      try {
        const [hctResponse, hgbResponse] = await Promise.all([
          this.api.v1DataPatientidDatatypesGet(
            params.patientid,
            "hct",
            params.start,
            params.stop
          ),
          this.api.v1DataPatientidDatatypesGet(
            params.patientid,
            "hgb",
            params.start,
            params.stop
          ),
        ]);

        this.hctData = hctResponse.data.hct;
        this.hgbData = hgbResponse.data.hgb;
        this.fetchHCTStatus = apiStatus.SUCCESS;
        this.fetchHGBStatus = apiStatus.SUCCESS;
      } catch (error) {
        this.fetchHCTStatus = apiStatus.ERROR;
        this.fetchHGBStatus = apiStatus.ERROR;
      }
    },
    getAndRedirect(type, epochS) {
      this.api
        .v1DataPatientidDatatypeTimestampGet(
          this.patientIdParameter,
          type,
          epochS
        )
        .then((response) => {
          if (
            response.data &&
            response?.data?.data?.length > 0 &&
            response?.data?.data[0]?.reads_id
          ) {
            this.navToReadsUri(response.data.data[0].reads_id);
          } else {
            this.$bus.emit(
              "show-general-error",
              this.$t("patient.cannotRedirectGenericError")
            );
          }
        })
        .catch((error) => {
          this.showApiResponseError(
            error,
            this.$t("patient.errorEngineeringLinkError")
          );
        });
    },
    navToReadsUri(readsId) {
      const uri = this.supportInfo.engineering_read_search;
      if (!uri) {
        this.$bus.emit(
          "show-general-error",
          this.$t("patient.errorEngineeringLinkError"),
          null
        );
        return;
      }

      const uriPopulated = uri.replace("{read_id}", readsId);

      const link = document.createElement("a");
      link.href = uriPopulated;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadFile(filename, text) {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(text));
      element.setAttribute('download', `${filename}.csv`);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    exportData() {
      const columns = [
        "patient_id",
        "timestamp",
        "potassium_value",
        "hr_value",
        "hct_value",
        "hgb_value",
      ];

      const groupedRows = Object.entries(groupBy(
        [].concat(
          this.potassiumData?.data?.map((td) => ({
            patient_id: this.patient.id,
            timestamp: new Date(td.x * 1000).toISOString(),
            potassium_value: td.y,
          })),
          this.heartRateData?.data?.map((td) => ({
            patient_id: this.patient.id,
            timestamp: new Date(td.x * 1000).toISOString(),
            hr_value: Math.round(td.y * 100) / 100,
          })),
          this.hctData?.data?.map((td) => ({
            patient_id: this.patient.id,
            timestamp: new Date(td.x * 1000).toISOString(),
            hct_value: Math.round(td.y * 100) / 100,
          })),
          this.hgbData?.data?.map((td) => ({
            patient_id: this.patient.id,
            timestamp: new Date(td.x * 1000).toISOString(),
            hgb_value: Math.round(td.y * 100) / 100,
          })),
        ),
        "timestamp"
      ));
      const rows = groupedRows
        .map((x) => Object.assign({}, ...(<any>x[1])));

      const data = columns.join(',') + '\n' + rows.map((r) => columns.map((c) => r[c]).join(',')).join('\n');
      this.downloadFile(
        `patient_${this.patient.id}_${new Date(this.lowerBound * 1000).toISOString().slice(0,10)}_to_${new Date(this.upperBound * 1000).toISOString().slice(0,10)}`,
        data,
      );
    },
    calculateAverage24Hours(data) {
      if (!data || data.length === 0) {
        return null;
      }
      const filteredData = data.filter(entry => {
        return entry.x >= this.lowerBound && entry.x <= this.upperBound;
      });

      if (filteredData.length === 0) {
        return null;
      }

      const sum = filteredData.reduce((total, entry) => total + entry.y, 0);
      const average = sum / filteredData.length;

      return average;
    }
  },
};
</script>

<style lang="scss" scoped>
.rbv-container {
  width: 100%;
  margin-bottom: 26px;
  border: 4px solid #826cab;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px;
}

#patient-availability {
  padding-top: 40px;
  width: 100%;
}

.status-message-container {
  padding-top: 40px;
  width: 100%;
}

.spinner-container {
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  width: 100%;
}

.patient-charts-container {
  min-height: 400px;
}
</style>
