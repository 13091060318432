<template>
  <div class="date-picker">
    <DateSelect
      v-for="(dateSelect, i) in dateSelects"
      :key="i"
      :index="i"
      :tabindex="tabStart + i"
      :type="dateSelect.type"
      :selected="dateSelect.selected"
      :options="dateSelect.options"
      @update="handleChange"
    />
  </div>
</template>
<script>
import DateSelect from "@/shared/components/DateSelect.vue";
export default {
  components: { DateSelect },
  props: {
    date: {
      type: String,
    },
    tabStart: {
      type: Number,
    },
    dob: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      day: null,
      month: null,
      year: null,
    };
  },
  computed: {
    dateSelects() {
      const lDateString = new Date(1992, 11, 31).toLocaleDateString(
        this.$i18n.locale
      );
      const dateSelects = [];
      const splitDate = lDateString.split(/[\.\/\-]/);
      for (let item of splitDate) {
        if (item === "1992") {
          dateSelects.push({
            type: "year",
            selected: this.year,
            options: this.generateYearOptions(),
          });
        } else if (item === "12") {
          dateSelects.push({
            type: "month",
            selected: this.month,
            options: this.generateMonthOptions(),
          });
        } else if (item === "31") {
          dateSelects.push({
            type: "day",
            selected: this.day,
            options: this.generateDayOptions(),
          });
        }
      }

      return dateSelects;
    },
    chosenDate() {
      if (this.day && this.month && this.year) {
        return `${this.year}-${this.month}-${this.day}`;
      } else {
        return null;
      }
    },
  },
  watch: {
    chosenDate: function () {
      this.$emit("date-change", this.chosenDate);
    },
    date: function () {
      this.setupDate();
    },
  },
  mounted() {
    this.setupDate();
  },
  methods: {
    setupDate() {
      if (this.date) {
        const splitDate = this.date.split("-");
        this.year = splitDate[0];
        this.month = splitDate[1];
        this.day = splitDate[2];
      } else {
        this.year = new Date().getFullYear();
        const m = new Date().getMonth() + 1;
        if (m < 10) {
          this.month = `0${m}`;
        } else {
          this.month = m;
        }
        const d = new Date().getDate();
        if (d < 10) {
          this.day = `0${d}`;
        } else {
          this.day = d;
        }
      }
    },
    handleChange(update) {
      this[update.type] = update.value;
    },
    generateYearOptions() {
      const currentYear = new Date().getFullYear();
      const yearOptions = [];

      if (this.dob) {
        for (let i = currentYear; i > currentYear - 120; i--) {
          yearOptions.push({ value: i.toString(), text: i.toString() });
        }
      } else {
        for (let i = currentYear; i < currentYear + 5; i++) {
          yearOptions.push({ value: i.toString(), text: i.toString() });
        }
      }
      return yearOptions;
    },
    generateMonthOptions() {
      const monthOptions = [];
      for (let i = 1; i < 13; i++) {
        let value = i;
        if (i < 10) {
          value = `0${value}`;
        }
        monthOptions.push({ value, text: this.$t(`months.${i}`) });
      }
      return monthOptions;
    },
    generateDayOptions() {
      const dayOptions = [];
      for (let i = 1; i < 32; i++) {
        let value = i;
        if (i < 10) {
          value = `0${value}`;
        }
        dayOptions.push({ value, text: i.toString() });
      }
      return dayOptions;
    },
  },
};
</script>
<style scoped lang="scss">
.date-picker {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
</style>
