<template>
  <div>
    <sub-header
      v-if="isClinicianApp()"
      :patient="patient"
      :last-refresh-date="lastRefreshDate"
      :action-buttons="actionButtonsClinician"
      :last-viewed-date="patient ? patient.lastviewtime : null"
      :last-viewed-clinician="patient ? patient.lastviewer : null"
      :show-refresh-button="false"
      @dashboard-button-event="navViewPatientList"
    />
    <sub-header
      v-else-if="isSupportApp()"
      :navigation-buttons-left="navigationButtonsSupport"
      :navigation-buttons-right="navigationButtonsSupportRight"
      :show-last-viewed="false"
      @view-patient-list-event="navViewPatientList"
      @view-kit-hub-patch-list-event="navViewKitHubPatchList"
    />

    <div class="container-fluid main-section patient-container">
      <div>
        <div
          v-if="state === State.Loading"
          id="patient-list-loading"
          class="data-loading-container"
        >
          <span>{{ $t("common.loading") }}</span>
        </div>
        <div
          v-else-if="state === State.FailedClinic"
          class="data-empty-container"
        >
          <span>{{ $t("clinic.errorLoadClinic") }}</span>
        </div>
        <div
          v-else-if="state === State.FailedPatient"
          class="data-empty-container"
        >
          <span>{{ $t("patient.errorNotFound") }}</span>
        </div>
        <div v-else id="patient-list">
          <patient-detail-table
            :ordinal="showOrdinalPotassium"
            :items="headerTableItems"
          />
        </div>
      </div>
      <div class="patient-data-container">
        <PatientCharts
          :patient="patient"
          :clinic="clinic"
          :support-info="supportInfo"
          @update-table-header="handleUpdateTable"
        />
        <PatientInfo
          v-if="patient && clinic"
          :patient="patient"
          :clinic="clinic"
          :clinic-patch-locations="clinicPatchLocations"
          :support-info="supportInfo"
          :hub-patch-data="hubPatchData"
          @refresh="getPatient"
        />
      </div>
      <div v-if="isSupportApp() && patient !== null" id="patient-hubs-patches">
        <section-header :header-text="$t('patient.hubs')" />
        <HubTable
          :hubs="patient?.hubs ? patient.hubs : []"
          :support-info="supportInfo"
          :patient-id="parseInt(patientIdParameter, 10)"
          @refresh="getPatient"
        />
        <section-header :header-text="$t('patient.patches')" />
        <PatchTable
          :patches="patient?.patches ? patient.patches : []"
          :support-info="supportInfo"
          :patient-id="parseInt(patientIdParameter, 10)"
          @refresh="getPatient"
        />
      </div>
    </div>

    <end-monitoring-modal
      modal-id="stop-monitoring-modal"
      :patient-id="parseInt(patientIdParameter, 10)"
      @updated="getPatient"
    />
  </div>
</template>
<script>
import PatientInfo from "@/shared/components/PatientInfo";
import PatientCharts from "@/shared/components/PatientCharts";
import PatchTable from "@/shared/components/PatchTable.vue";
import HubTable from "@/shared/components/HubTable.vue";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import PatientDetailTable from "@/shared/components/PatientDetailTable.vue";

const State = Object.freeze({
  Loading: 1,
  FailedClinic: 2,
  FailedPatient: 3,
  Done: 4,
});

export default {
  name: "PatientDetail",
  components: {
    PatientDetailTable,
    HubTable,
    PatchTable,
    PatientInfo,
    PatientCharts,
    SectionHeader,
  },
  data: function () {
    return {
      State,
      next: null,
      state: State.Loading,
      pageCreated: null,
      lastRefreshDate: null,
      headerTableItems: [
        {
          k_abnormal_reads_percent: null,
          k_high_reads_percent: null,
          k_low_reads_percent: null,
          hgb_high_reads_percent: null,
          hgb_low_reads_percent: null,
          hct_high_reads_percent: null,
          hct_low_reads_percent: null,
          number_notifiable_events: null,
        },
      ],
      // Subheader
      navigationButtonsSupport: [
        {
          label: this.$t("navigation.viewPatientListBack"),
          id: "view-patient-list-sub-header-button",
          event: "view-patient-list-event",
        },
      ],
      navigationButtonsSupportRight: [
        {
          label: this.$t("navigation.viewKitHubPatchList"),
          id: "view-kit-hub-patch-list-sub-header-button",
          event: "view-kit-hub-patch-list-event",
        },
      ],
      actionButtonsClinician: [
        {
          label: this.$t("navigation.dashboard"),
          id: "dashboard-sub-header-button",
          event: "dashboard-button-event",
        },
      ],
      // Support info
      supportInfoResult: null,
      supportInfo: null,
      // Clinic
      clinicResult: null,
      clinic: null,
      // Patient
      patient: null,
    };
  },
  computed: {
    showOrdinalPotassium() {
      return this.clinic?.ordinal_potassium;
    },
    clinicIdParameter: function () {
      return parseInt(this.$route.params.clinicId, 10);
    },
    patientIdParameter: function () {
      return parseInt(this.$route.params.patientId, 10);
    },
    clinicPatchLocations: function () {
      return this.clinic ? this.clinic.patchlocations : [];
    },
    hubPatchData: function () {
      return this.hubPatchDataFromPatient(this.patient);
    },
  },
  created: function () {
    this.pageCreated = new Date();
    // Make sure the clinic ID is valid
    if (this.verifyClinicId(this.clinicIdParameter)) {
      if (this.isSupportApp()) {
        this.getSupportInfo();
      }
      this.getClinic();
    }
  },
  methods: {
    handleUpdateTable(update) {
      this.headerTableItems = [{ ...this.headerTableItems[0], ...update }];
    },
    getSupportInfo: function () {
      this.supportInfoResult = null;
      this.supportInfo = null;

      // Load the support info
      this.api
        .v1SupportinfoGet()
        .then((response) => {
          this.supportInfo = response.data;
          this.supportInfoResult = true;
        })
        .catch((error) => {
          this.supportInfoResult = false;
          // Get the error and display the error
          this.showApiResponseError(error, this.$t("patient.errorLoad"));
        });
    },
    getClinic: function () {
      this.clinic = null;

      // Load the clinic info
      this.getClinicInfo(
        this.clinicIdParameter,
        true,
        (clinic) => {
          this.clinic = clinic;
          this.clinicResult = true;

          // The patient information is dependent on some of the clinic information,
          // so need to get this first successfully before getting the patient.
          this.getPatient();
        },
        (error) => {
          this.state = this.State.FailedClinic;
          this.showApiResponseError(error, this.$t("clinic.errorLoadDetails"));
        }
      );
    },
    getPatient: async function () {
      if (this.patientIdParameter < 0) {
        this.$bus.emit(
          "show-general-error",
          this.$t("patient.errorUnspecified")
        );
      } else {
        // Clear the last refresh date
        this.lastRefreshDate = null;

        // Load the patient info
        this.api
          .v1PatientPatientidGet(this.patientIdParameter)
          .then(async (response) => {
            this.lastRefreshDate = new Date();
            if (response.data.patient.clinic !== this.clinicIdParameter) {
              this.$bus.emit(
                "show-general-error",
                this.$t("patient.errorInvalidClinic")
              );
            } else {
              this.patient = response.data.patient;
              this.headerTableItems = [
                {
                  ...this.headerTableItems[0],
                  status: this.patient.statusdesc,
                  lastdataseen: this.patient.lastdataseen,
                },
              ];
              this.state = State.Done;
            }
          })
          .catch((error) => {
            this.state = State.FailedPatient;
            // Get the error and display the error
            this.showApiResponseError(error, this.$t("patient.errorLoad"));
          });
      }
    },
    navViewPatientList: function () {
      this.$router.push({
        name: "patient-list",
        params: { clinicId: this.clinicIdParameter },
      });
    },
    navViewKitHubPatchList: function () {
      this.$router.push({ name: "kit-hub-patch-list" });
    },
  },
};
</script>
<style lang="scss">
.patient-data-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: visible;
  width: 100%;

  > div {
    &:first-child {
      flex: 1 1 auto;
      min-width: 200px;
      overflow: visible;
    }

    &:not(:first-child) {
      margin-left: 48px;
      flex: 0 0 400px;
      overflow: auto;
    }
  }
}

#patient-list {
  overflow-x: auto;
}

#patient-hubs-patches {
  padding-top: 26px;
  width: 100%;
}

#patient-hubs-patches-heading {
  display: flex;
  align-items: center;

  color: $gray-dark;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27px;

  text-transform: uppercase;

  > * {
    &:first-child {
      margin-right: 16px;
    }
  }
}

@media (max-width: 959px) {
  .patient-data-container {
    flex-wrap: nowrap;
    flex-direction: column;

    > div {
      &:first-child {
        flex: 1 1 auto;
        overflow: visible;
      }

      &:not(:first-child) {
        margin-left: 0px;
        flex: 1 1 auto;
        overflow: auto;
      }
    }
  }
}
</style>
