import "@/shared/global-shim.js";
import Vue from "vue";
import VueI18n from "vue-i18n";
import BootstrapVue from "bootstrap-vue";
import VueBus from "vue-bus";

import axios from "axios";
import { setDomain, setAxiosInstance } from "@/shared/api.js";
import { Amplify } from "aws-amplify";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DefaultApi, Configuration } from "@/shared/api-client";

// Main App
import App from "@/clinician/App.vue";

// Mixins
import AppMixin from "@/shared/mixins/AppMixin";
import ApiMixin from "@/shared/mixins/ApiMixin";
import FieldUtilitiesMixin from "@/shared/mixins/FieldUtilitiesMixin";
import UtilitiesMixin from "@/shared/mixins/UtilitiesMixin";

// Components
import AutocompleteTextField from "@/shared/components/AutocompleteTextField.vue";
import MainHeader from "@/shared/components/MainHeader.vue";
import InstructionsForUseModal from "@/shared/components/InstructionsForUseModal.vue";
import SubHeader from "@/shared/components/SubHeader.vue";
import GeneralSuccessModal from "@/shared/components/GeneralSuccessModal.vue";
import GeneralErrorModal from "@/shared/components/GeneralErrorModal.vue";
import ConfirmationPromptModal from "@/shared/components/ConfirmationPromptModal.vue";
import ForgotPasswordModal from "@/shared/components/ForgotPasswordModal.vue";
import ForgotPasswordResetModal from "@/shared/components/ForgotPasswordResetModal.vue";
import PasswordChangeModal from "@/shared/components/PasswordChangeModal.vue";
import PrivacyPolicyModal from "@/shared/components/PrivacyPolicyModal.vue";
import ChartHeader from "@/shared/components/ChartHeader.vue";
import AudioFileListModal from "@/shared/components/AudioFileListModal.vue";
import PatientListTable from "@/shared/components/PatientListTable.vue";
import EndMonitoringModal from "@/shared/components/EndMonitoringModal.vue";
import DatePicker from "@/shared/components/DatePicker.vue";
import PotassiumSettings from "@/shared/components/PotassiumSettings.vue";
import HCTOrHGBSettings from "@/shared/components/HCTOrHGBSettings.vue";
import ImportPatientsModal from "@/shared/components/ImportPatientsModal.vue";
import UserGroupList from "@/shared/components/UserGroupList.vue";
import AddUserGroupModal from "@/shared/components/AddUserGroupModal.vue";

Vue.component("AutocompleteTextField", AutocompleteTextField);
Vue.component("MainHeader", MainHeader);
Vue.component("InstructionsForUseModal", InstructionsForUseModal);
Vue.component("SubHeader", SubHeader);
Vue.component("GeneralSuccessModal", GeneralSuccessModal);
Vue.component("GeneralErrorModal", GeneralErrorModal);
Vue.component("ConfirmationPromptModal", ConfirmationPromptModal);
Vue.component("ForgotPasswordModal", ForgotPasswordModal);
Vue.component("ForgotPasswordResetModal", ForgotPasswordResetModal);
Vue.component("PasswordChangeModal", PasswordChangeModal);
Vue.component("PrivacyPolicyModal", PrivacyPolicyModal);
Vue.component("PatientListTable", PatientListTable);
Vue.component("ChartHeader", ChartHeader);
Vue.component("AudioFileListModal", AudioFileListModal);
Vue.component("EndMonitoringModal", EndMonitoringModal);
Vue.component("DatePicker", DatePicker);
Vue.component("PotassiumSettings", PotassiumSettings);
Vue.component("HctOrHgbSettings", HCTOrHGBSettings);
Vue.component("ImportPatientsModal", ImportPatientsModal);
Vue.component("UserGroupList", UserGroupList);
Vue.component("AddUserGroupModal", AddUserGroupModal);

// Install Vue package options
Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.use(VueBus);

// Register mixins
Vue.mixin(AppMixin);
Vue.mixin(ApiMixin);
Vue.mixin(FieldUtilitiesMixin);
Vue.mixin(UtilitiesMixin);

// Set App Type
Vue.prototype.$APP_TYPE = "clinician";

axios.interceptors.request.use(async (config) => {
  // Setup the main auth header for the current session. Note that
  // if there is a "Signature=" in the the URL, this is considered the
  // authorization for the URL and we should NOT include the normal
  // Authorization header or it will fail. This normally involves time
  // limited media files.
  if (config.url && !config.url.includes("Signature=")) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const session = await Amplify.Auth.currentSession();

      if (session) {
        if (session.idToken && session.idToken.jwtToken) {
          config.headers.Authorization = session.idToken.jwtToken;
        }
      }
    } catch (error) {
      // Ignore any errors
    }
  }

  return config;
});

const config = new Configuration({ basePath: import.meta.env.VITE_API_SERVER });

Vue.prototype.api = new DefaultApi(
  config,
  import.meta.env.VITE_API_SERVER,
  axios
);

// Add in support for axios
Vue.prototype.axios = axios;

// Setup domain and axios instance
setDomain(import.meta.env.VITE_API_SERVER);
setAxiosInstance(axios);

// Import the store
import store from "@/clinician/store";

// If there is an awsConfig, then use it configure Amplify
import { getClinicianAmplifyConfig } from "@/shared/utils";
console.log(import.meta.env);
const awsConfig = getClinicianAmplifyConfig(store);
console.log(awsConfig);
if (awsConfig) {
  Amplify.configure(awsConfig);
}

// Route information for the Vue router
import router from "@/clinician/routes.js";
import createI18n from "@/shared/i18n";

createI18n().then((i18n) => {
  const app = new Vue({
    store,
    i18n,
    router,
    render: (h) => h(App),
  });

  app.$mount("#app");
});
