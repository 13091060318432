/* eslint-disable vue/no-mutating-props */
<template>
  <div class="tw-w-full tw-pb-4">
    <slot name="header"></slot>
    <div
      v-if="fetchStatus === apiStatus.LOADING || fetchStatus === apiStatus.IDLE"
      class="data-empty-container"
    >
      <span>{{ $t("patient.loadingData") }}</span>
    </div>
    <div
      v-else-if="
        fetchStatus === apiStatus.SUCCESS &&
        !arrayExistsWithData(chartData.data)
      "
      class="data-empty-container"
    >
      <span>{{ $t("patient.errorNoData") }}</span>
    </div>
    <div v-else-if="fetchStatus === apiStatus.ERROR">
      <span>{{ $t("patient.failedGetData") }}</span>
    </div>
    <div v-else>
      <b-row class="chart-row">
        <div class="chart-label-container">
          <p class="chart-label-y-axis-left chart-label">
            {{ label }}
          </p>
        </div>
        <slot name="chart"></slot>
      </b-row>
    </div>
  </div>
</template>

<script lang="ts">
import { apiStatus } from "@/shared/constants";

export default {
  name: "ChartContainer",
  props: {
    fetchStatus: {
      type: apiStatus,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartType: {
      validator: function (value) {
        return (
          [
            "potassium",
            "auscultation",
            "availability",
            "temperature",
            "hr",
            "hrv",
            "spo2",
            "hct",
            "hgb",
          ].indexOf(value) !== -1
        );
      },
      required: true,
    },
  },
  data() {
    return {
      label: null,
      unitsRange: null,
      unitsRangeDisplay: null,
      rangeUnitMapping: {
        temperature: {
          fahrenheit: this.$t("chart.labelRangeTemperatureF"),
          celsius: this.$t("chart.labelRangeTemperatureC"),
        },
        hr: {
          bpm: this.$t("chart.labelRangeBeatsPerMinute"),
        },
        hrv: {
          ms: this.$t("chart.labelRangeMilliseconds"),
        },
        spo2: {
          "%": this.$t("chart.labelRangeOxygenSaturation"),
        },
        hct: {
          "%": this.$t("chart.labelRangeHematocrit"),
        },
        hgb: {
          "g/dL": this.$t("chart.labelRangeHemoglobin"),
        },
      },
    };
  },
  computed: {
    apiStatus() {
      return apiStatus;
    },
    hasYLabels() {
      return (
        this.chartType !== "availability" &&
        this.chartType !== "auscultation" &&
        this.chartType !== "potassium"
      );
    },
  },
  mounted() {
    if (this.hasYLabels) {
      this.unitsRange = this.chartData.rangeunits;
      this.unitsRangeDisplay = this.chartData.rangedisplayunits
        ? this.chartData.rangedisplayunits
        : this.chartData.rangeunits;
      this.label =
        this.rangeUnitMapping[this.chartType][this.unitsRangeDisplay];
    }

    if (this.chartType === "potassium") {
      this.label = this.$t("chart.labelRangePotassium");
    }
  },
};
</script>
