<template>
  <div ref="chartdiv" :class="chartClass"></div>
</template>

<script>
import ChartMixin from "@/shared/mixins/ChartMixin";
import { Tooltip, Root } from "@amcharts/amcharts5";
import {
  AxisRendererX,
  AxisRendererY,
  DateAxis,
  LineSeries,
  ValueAxis,
  XYChart,
  XYCursor,
} from "@amcharts/amcharts5/xy";
import {
  calcFixedPosition,
  chartProps,
} from "@/shared/components/Charts/chartCommon";

export default {
  name: "TempChart",
  mixins: [ChartMixin],
  props: {
    ...chartProps,
    chartType: {
      validator: function (value) {
        return ["temperature"].indexOf(value) !== -1;
      },
      required: true,
    },
  },
  data() {
    return {
      chartLoaded: false,
      exporting: null,
      label: null,
      unitsRange: null,
      unitsRangeDisplay: null,
      rangeUnitMapping: {
        temperature: {
          fahrenheit: this.$t("chart.labelRangeTemperatureF"),
          celsius: this.$t("chart.labelRangeTemperatureC"),
        },
      },
    };
  },
  computed: {
    chartClass() {
      if (!this.chartLoaded) return "hidden-chart";
      return "chart";
    },
  },
  watch: {
    chartData: function () {
      this.destroyRoot();
      this.createChart();
    },
  },
  mounted() {
    this.createChart();
  },

  beforeDestroy() {
    this.destroyRoot();
  },
  methods: {
    destroyRoot() {
      if (this.root) {
        this.root.dispose();
      }
    },
    createChart() {
      this.unitsRange = this.chartData.rangeunits;
      this.unitsRangeDisplay = this.chartData.rangedisplayunits
        ? this.chartData.rangedisplayunits
        : this.chartData.rangeunits;
      this.label =
        this.rangeUnitMapping[this.chartType][this.unitsRangeDisplay];

      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      this.root = Root.new(this.$refs.chartdiv);

      this.root.setThemes([this.getTheme(this.root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      const chart = this.root.container.children.push(
        XYChart.new(this.root, {
          maxTooltipDistance: 0,
          paddingRight: 16,
        })
      );
      chart.zoomOutButton.set("forceHidden", true);

      const yAxis = this.setupYAxis(chart, this.root);
      const xAxis = this.setupXAxis(chart, this.root);

      const series = this.setupSeries(chart, this.root, xAxis, yAxis);

      series.data.setAll(this.setupData());
      const cursorSeries = [series];

      if (this.events.length > 0) {
        const dialysisEvents = this.setupDialysisEvents(
          calcFixedPosition(this.chartData)
        );
        cursorSeries.push(
          this.addDialysisEventsSeries(
            this.root,
            chart,
            xAxis,
            yAxis,
            dialysisEvents
          )
        );
      }

      chart.set(
        "cursor",
        XYCursor.new(this.root, {
          xAxis,
          snapToSeries: cursorSeries,
        })
      );

      series.appear(1000);
      chart.appear(1000, 100);

      this.chartLoaded = true;
    },
    roundToMultipleOfFive(num) {
      return Math.round(num / 5) * 5;
    },
    setupSeries(chart, root, xAxis, yAxis) {
      const tooltip = Tooltip.new(root, {
        labelText: this.getTooltipText(),
        getFillFromSprite: false,
      });

      const settings = {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip,
        showTooltipOn: "always",
      };

      settings.connect = this.getLineConnect();
      settings.autoGapCount = this.getAutoGapCount(
        this.chartType,
        this.acute,
        this.acuteMode
      );
      settings.stroke = this.ALIO_SUCCESS_GREEN;
      settings.fill = this.ALIO_SUCCESS_GREEN;

      const series = chart.series.push(LineSeries.new(root, settings));
      series.strokes.template.setAll({
        strokeWidth: 2,
      });

      tooltip.get("background").setAll({
        fill: this.ALIO_PURPLE,
        fillOpacity: 1,
        strokeWidth: 0,
      });
      return series;
    },
    setupXAxis(chart, root) {
      const tooltip = Tooltip.new(root, {});

      const settings = {
        maxDeviation: 0,
        max: this.upperBound * 1000,
        min: this.lowerBound * 1000,
        baseInterval: {
          timeUnit: "minute",
          count: 1,
        },
        renderer: AxisRendererX.new(root, { minGridDistance: 150 }),
        tooltip,
        tooltipDateFormats: {
          minute: "hh:mm a",
          hour: "hh:mm a",
          day: "[bold]eee[/] MMM dd",
          week: "[bold]eee[/] MMM dd",
        },
        dateFormats: {
          minute: "hh:mm a",
          hour: "hh:mm a",
          day: "[bold]eee[/] MMM dd",
          week: "[bold]eee[/] MMM dd",
        },
        periodChangeDateFormats: {
          minute: "[bold]eee[/] MMM dd",
          hour: "[bold]eee[/] MMM dd",
          day: "[bold]eee[/] MMM dd",
          week: "[bold]eee[/] MMM dd",
        },
        keepSelection: true,
      };

      const xAxis = chart.xAxes.push(DateAxis.new(root, settings));

      tooltip.get("background").setAll({
        fill: this.ALIO_PURPLE,
        fillOpacity: 1,
        strokeWidth: 0,
      });

      return xAxis;
    },
    setupYAxis(chart, root) {
      let min = this.chartData.minrange;
      let max = this.chartData.maxrange;

      if (this.unitsRangeDisplay === "fahrenheit") {
        min = this.roundToMultipleOfFive(
          this.convertTemperature(
            this.chartData.minrange,
            this.unitsRange,
            this.unitsRangeDisplay
          )
        );
        max = this.roundToMultipleOfFive(
          this.convertTemperature(
            this.chartData.maxrange,
            this.unitsRange,
            this.unitsRangeDisplay
          )
        );
      }

      const yAxis = chart.yAxes.push(
        ValueAxis.new(root, {
          renderer: AxisRendererY.new(root, {
            opposite: true,
            minGridDistance: 30,
          }),
          strictMinMax: true,
          max,
          min,
        })
      );

      const yRenderer = yAxis.get("renderer");
      yRenderer.setAll({
        minWidth: 35,
        opposite: true,
      });

      this.createGrid(yAxis, min, true, false, true);
      this.createGrid(yAxis, max, true, false, true);

      return yAxis;
    },
    getTooltipText() {
      return `{valueY.formatNumber('#,###.0')}${this.getUnitsString(
        this.unitsRangeDisplay
      )}`;
    },
    setupData() {
      return this.chartData.data.map((entry) => {
        const date = entry.x * 1000; // turn to ms
        const chartEntry = { date };
        chartEntry.value = this.convertTemperature(
          entry.y,
          this.unitsRange,
          this.unitsRangeDisplay
        );

        return chartEntry;
      });
    },
  },
};
</script>
