<template>
  <div class="pagination">
    <button class="pagination-btn" :disabled="value === 1" @click="prevPage">
      <BIconCaretLeftFill />
    </button>
    <span>Page {{ value }} of {{ totalPages }}</span>
    <button
      class="pagination-btn"
      :disabled="value === totalPages"
      @click="nextPage"
    >
      <BIconCaretRightFill />
    </button>
  </div>
</template>

<script>
import { BIconCaretLeftFill, BIconCaretRightFill } from "bootstrap-vue";

export default {
  name: "Pagination",
  components: {
    BIconCaretLeftFill,
    BIconCaretRightFill,
  },
  props: {
    totalRows: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalRows / this.perPage);
    },
  },
  methods: {
    nextPage() {
      if (this.value < this.totalPages) {
        this.$emit("input", this.value + 1);
      }
    },
    prevPage() {
      if (this.value > 1) {
        this.$emit("input", this.value - 1);
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-btn {
  width: 100% !important;
  max-width: 0px !important;
}
</style>
