/* eslint-disable */
import axios from "axios";
import qs from "qs";
let domain = "";
let axiosInstance = axios.create();
export const getDomain = () => {
  return domain;
};
export const setDomain = ($domain) => {
  domain = $domain;
};
export const getAxiosInstance = () => {
  return axiosInstance;
};
export const setAxiosInstance = ($axiosInstance) => {
  axiosInstance = $axiosInstance;
};
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase();
  let keys = Object.keys(queryParameters);
  let queryUrl = url;
  if (keys.length > 0) {
    queryUrl = url + "?" + qs.stringify(queryParameters);
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axiosInstance[method](queryUrl, body, config);
  } else if (
    method === "get" ||
    method === "delete" ||
    method === "head" ||
    method === "option"
  ) {
    return axiosInstance[method](queryUrl, config);
  } else {
    return axiosInstance[method](queryUrl, form, config);
  }
};

/*==========================================================
 *                    powered by Flasgger
 ==========================================================*/
/**
 * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/>
 * request: postV1Clinic
 * url: postV1ClinicURL
 * method: postV1Clinic_TYPE
 * raw_url: postV1Clinic_RAW_URL
 * @param createclinic -
 * @param sitename -
 */
export const postV1Clinic = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinic";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["createclinic"] !== undefined) {
    body = parameters["createclinic"];
  }
  if (parameters["createclinic"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: createclinic")
    );
  }
  if (parameters["sitename"] !== undefined) {
    body = parameters["sitename"];
  }
  if (parameters["sitename"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: sitename"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1Clinic_RAW_URL = function () {
  return "/v1/clinic";
};
export const postV1Clinic_TYPE = function () {
  return "post";
};
export const postV1ClinicURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinic";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Retrieves a clinic's record
 * request: getV1ClinicByClinicid
 * url: getV1ClinicByClinicidURL
 * method: getV1ClinicByClinicid_TYPE
 * raw_url: getV1ClinicByClinicid_RAW_URL
 * @param clinicid -
 */
export const getV1ClinicByClinicid = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinic/{clinicid}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters["clinicid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: clinicid"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1ClinicByClinicid_RAW_URL = function () {
  return "/v1/clinic/{clinicid}";
};
export const getV1ClinicByClinicid_TYPE = function () {
  return "get";
};
export const getV1ClinicByClinicidURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinic/{clinicid}";
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * which are completely omitted are not altered.<br/>
 * request: putV1ClinicByClinicid
 * url: putV1ClinicByClinicidURL
 * method: putV1ClinicByClinicid_TYPE
 * raw_url: putV1ClinicByClinicid_RAW_URL
 * @param clinicid -
 * @param updateclinic -
 */
export const putV1ClinicByClinicid = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinic/{clinicid}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters["clinicid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: clinicid"));
  }
  if (parameters["updateclinic"] !== undefined) {
    body = parameters["updateclinic"];
  }
  if (parameters["updateclinic"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: updateclinic")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("put", domain + path, body, queryParameters, form, config);
};
export const putV1ClinicByClinicid_RAW_URL = function () {
  return "/v1/clinic/{clinicid}";
};
export const putV1ClinicByClinicid_TYPE = function () {
  return "put";
};
export const putV1ClinicByClinicidURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinic/{clinicid}";
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Enumerates the patients you have access to from a specific clinic.
 * request: getV1ClinicByClinicidPatients
 * url: getV1ClinicByClinicidPatientsURL
 * method: getV1ClinicByClinicidPatients_TYPE
 * raw_url: getV1ClinicByClinicidPatients_RAW_URL
 * @param clinicid -
 * @param limit - Number of records to return,
 * @param skip - Number of records to skip over.
 * @param sort - Comma separated list of field names to sort by
 * @param dir - Comma separated list of ASC or DESC, corresponding to the fields in sort. If dir is shorter than sort, the last direction will be used for all remaining fields.
 * @param expectedmaxpatientid - The largest expected patient ID, so that we can abort some of the expensive work if a patient has been added mid-listing.
 */
export const getV1ClinicByClinicidPatients = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinic/{clinicid}/patients";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters["limit"] !== undefined) {
    queryParameters["limit"] = parameters["limit"];
  }
  if (parameters["skip"] !== undefined) {
    queryParameters["skip"] = parameters["skip"];
  }
  if (parameters["sort"] !== undefined) {
    queryParameters["sort"] = parameters["sort"];
  }
  if (parameters["dir"] !== undefined) {
    queryParameters["dir"] = parameters["dir"];
  }
  if (parameters["expectedmaxpatientid"] !== undefined) {
    queryParameters["expectedmaxpatientid"] =
      parameters["expectedmaxpatientid"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1ClinicByClinicidPatients_RAW_URL = function () {
  return "/v1/clinic/{clinicid}/patients";
};
export const getV1ClinicByClinicidPatients_TYPE = function () {
  return "get";
};
export const getV1ClinicByClinicidPatientsURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinic/{clinicid}/patients";
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters["limit"] !== undefined) {
    queryParameters["limit"] = parameters["limit"];
  }
  if (parameters["skip"] !== undefined) {
    queryParameters["skip"] = parameters["skip"];
  }
  if (parameters["sort"] !== undefined) {
    queryParameters["sort"] = parameters["sort"];
  }
  if (parameters["dir"] !== undefined) {
    queryParameters["dir"] = parameters["dir"];
  }
  if (parameters["expectedmaxpatientid"] !== undefined) {
    queryParameters["expectedmaxpatientid"] =
      parameters["expectedmaxpatientid"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Creates a new session type for a clinic.
 * request: postV1ClinicByClinicidSession
 * url: postV1ClinicByClinicidSessionURL
 * method: postV1ClinicByClinicidSession_TYPE
 * raw_url: postV1ClinicByClinicidSession_RAW_URL
 * @param clinicid -
 * @param createsessiontype -
 */
export const postV1ClinicByClinicidSession = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinic/{clinicid}/session";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters["clinicid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: clinicid"));
  }
  if (parameters["createsessiontype"] !== undefined) {
    body = parameters["createsessiontype"];
  }
  if (parameters["createsessiontype"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: createsessiontype")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1ClinicByClinicidSession_RAW_URL = function () {
  return "/v1/clinic/{clinicid}/session";
};
export const postV1ClinicByClinicidSession_TYPE = function () {
  return "post";
};
export const postV1ClinicByClinicidSessionURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinic/{clinicid}/session";
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Updates an existing session type
 * request: putV1ClinicByClinicidSessionBySessiontypeid
 * url: putV1ClinicByClinicidSessionBySessiontypeidURL
 * method: putV1ClinicByClinicidSessionBySessiontypeid_TYPE
 * raw_url: putV1ClinicByClinicidSessionBySessiontypeid_RAW_URL
 * @param clinicid -
 * @param sessiontypeid -
 * @param updatesessiontype -
 */
export const putV1ClinicByClinicidSessionBySessiontypeid = function (
  parameters = {}
) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinic/{clinicid}/session/{sessiontypeid}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters["clinicid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: clinicid"));
  }
  path = path.replace("{sessiontypeid}", `${parameters["sessiontypeid"]}`);
  if (parameters["sessiontypeid"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: sessiontypeid")
    );
  }
  if (parameters["updatesessiontype"] !== undefined) {
    body = parameters["updatesessiontype"];
  }
  if (parameters["updatesessiontype"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: updatesessiontype")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("put", domain + path, body, queryParameters, form, config);
};
export const putV1ClinicByClinicidSessionBySessiontypeid_RAW_URL = function () {
  return "/v1/clinic/{clinicid}/session/{sessiontypeid}";
};
export const putV1ClinicByClinicidSessionBySessiontypeid_TYPE = function () {
  return "put";
};
export const putV1ClinicByClinicidSessionBySessiontypeidURL = function (
  parameters = {}
) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinic/{clinicid}/session/{sessiontypeid}";
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  path = path.replace("{sessiontypeid}", `${parameters["sessiontypeid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Enumerates the list of session types for a clinic.
 * request: getV1ClinicByClinicidSessiontypes
 * url: getV1ClinicByClinicidSessiontypesURL
 * method: getV1ClinicByClinicidSessiontypes_TYPE
 * raw_url: getV1ClinicByClinicidSessiontypes_RAW_URL
 * @param clinicid -
 */
export const getV1ClinicByClinicidSessiontypes = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinic/{clinicid}/sessiontypes";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters["clinicid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: clinicid"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1ClinicByClinicidSessiontypes_RAW_URL = function () {
  return "/v1/clinic/{clinicid}/sessiontypes";
};
export const getV1ClinicByClinicidSessiontypes_TYPE = function () {
  return "get";
};
export const getV1ClinicByClinicidSessiontypesURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinic/{clinicid}/sessiontypes";
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * <br/>Note, currently it will simply return empty statistics if you request them for a clinic<br/>which doesn't exist, or which you don't have permission to see. Similarly, a clinic<br/>without patients will have zeros across the board, rather than any kind of indication.<br/>
 * request: getV1ClinicByClinicidStats
 * url: getV1ClinicByClinicidStatsURL
 * method: getV1ClinicByClinicidStats_TYPE
 * raw_url: getV1ClinicByClinicidStats_RAW_URL
 * @param clinicid -
 */
export const getV1ClinicByClinicidStats = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinic/{clinicid}/stats";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters["clinicid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: clinicid"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1ClinicByClinicidStats_RAW_URL = function () {
  return "/v1/clinic/{clinicid}/stats";
};
export const getV1ClinicByClinicidStats_TYPE = function () {
  return "get";
};
export const getV1ClinicByClinicidStatsURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinic/{clinicid}/stats";
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Removes a clinic user from a clinic.
 * request: deleteV1ClinicByClinicidUserByClinicuserid
 * url: deleteV1ClinicByClinicidUserByClinicuseridURL
 * method: deleteV1ClinicByClinicidUserByClinicuserid_TYPE
 * raw_url: deleteV1ClinicByClinicidUserByClinicuserid_RAW_URL
 * @param clinicid -
 * @param clinicuserid -
 */
export const deleteV1ClinicByClinicidUserByClinicuserid = function (
  parameters = {}
) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinic/{clinicid}/user/{clinicuserid}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters["clinicid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: clinicid"));
  }
  path = path.replace("{clinicuserid}", `${parameters["clinicuserid"]}`);
  if (parameters["clinicuserid"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: clinicuserid")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("delete", domain + path, body, queryParameters, form, config);
};
export const deleteV1ClinicByClinicidUserByClinicuserid_RAW_URL = function () {
  return "/v1/clinic/{clinicid}/user/{clinicuserid}";
};
export const deleteV1ClinicByClinicidUserByClinicuserid_TYPE = function () {
  return "delete";
};
export const deleteV1ClinicByClinicidUserByClinicuseridURL = function (
  parameters = {}
) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinic/{clinicid}/user/{clinicuserid}";
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  path = path.replace("{clinicuserid}", `${parameters["clinicuserid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Enumerates the clinic users you have access to from a specific clinic
 * request: getV1ClinicByClinicidUsers
 * url: getV1ClinicByClinicidUsersURL
 * method: getV1ClinicByClinicidUsers_TYPE
 * raw_url: getV1ClinicByClinicidUsers_RAW_URL
 * @param clinicid -
 */
export const getV1ClinicByClinicidUsers = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinic/{clinicid}/users";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1ClinicByClinicidUsers_RAW_URL = function () {
  return "/v1/clinic/{clinicid}/users";
};
export const getV1ClinicByClinicidUsers_TYPE = function () {
  return "get";
};
export const getV1ClinicByClinicidUsersURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinic/{clinicid}/users";
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Logs the current user as having seen the clinic's patient list.
 * request: postV1ClinicByClinicidViewed
 * url: postV1ClinicByClinicidViewedURL
 * method: postV1ClinicByClinicidViewed_TYPE
 * raw_url: postV1ClinicByClinicidViewed_RAW_URL
 * @param clinicid -
 */
export const postV1ClinicByClinicidViewed = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinic/{clinicid}/viewed";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters["clinicid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: clinicid"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1ClinicByClinicidViewed_RAW_URL = function () {
  return "/v1/clinic/{clinicid}/viewed";
};
export const postV1ClinicByClinicidViewed_TYPE = function () {
  return "post";
};
export const postV1ClinicByClinicidViewedURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinic/{clinicid}/viewed";
  path = path.replace("{clinicid}", `${parameters["clinicid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/>
 * request: postV1Clinicadmin
 * url: postV1ClinicadminURL
 * method: postV1Clinicadmin_TYPE
 * raw_url: postV1Clinicadmin_RAW_URL
 * @param createclinicadmin -
 */
export const postV1Clinicadmin = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinicadmin";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["createclinicadmin"] !== undefined) {
    body = parameters["createclinicadmin"];
  }
  if (parameters["createclinicadmin"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: createclinicadmin")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1Clinicadmin_RAW_URL = function () {
  return "/v1/clinicadmin";
};
export const postV1Clinicadmin_TYPE = function () {
  return "post";
};
export const postV1ClinicadminURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinicadmin";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Enumerates the subset of clinics you have access to.
 * request: getV1Clinics
 * url: getV1ClinicsURL
 * method: getV1Clinics_TYPE
 * raw_url: getV1Clinics_RAW_URL
 */
export const getV1Clinics = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinics";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1Clinics_RAW_URL = function () {
  return "/v1/clinics";
};
export const getV1Clinics_TYPE = function () {
  return "get";
};
export const getV1ClinicsURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinics";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/>
 * request: postV1Clinicuser
 * url: postV1ClinicuserURL
 * method: postV1Clinicuser_TYPE
 * raw_url: postV1Clinicuser_RAW_URL
 * @param createclinicuser -
 */
export const postV1Clinicuser = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinicuser";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["createclinicuser"] !== undefined) {
    body = parameters["createclinicuser"];
  }
  if (parameters["createclinicuser"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: createclinicuser")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1Clinicuser_RAW_URL = function () {
  return "/v1/clinicuser";
};
export const postV1Clinicuser_TYPE = function () {
  return "post";
};
export const postV1ClinicuserURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinicuser";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Accepts privacy policy on behalf of signed-in user
 * request: putV1ClinicuserSelfAcceptprivacypolicy
 * url: putV1ClinicuserSelfAcceptprivacypolicyURL
 * method: putV1ClinicuserSelfAcceptprivacypolicy_TYPE
 * raw_url: putV1ClinicuserSelfAcceptprivacypolicy_RAW_URL
 * @param acceptance -
 */
export const putV1ClinicuserSelfAcceptprivacypolicy = function (
  parameters = {}
) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinicuser/self/acceptprivacypolicy";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["acceptance"] !== undefined) {
    body = parameters["acceptance"];
  }
  if (parameters["acceptance"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: acceptance"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("put", domain + path, body, queryParameters, form, config);
};
export const putV1ClinicuserSelfAcceptprivacypolicy_RAW_URL = function () {
  return "/v1/clinicuser/self/acceptprivacypolicy";
};
export const putV1ClinicuserSelfAcceptprivacypolicy_TYPE = function () {
  return "put";
};
export const putV1ClinicuserSelfAcceptprivacypolicyURL = function (
  parameters = {}
) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinicuser/self/acceptprivacypolicy";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Performs any actions that should occur when the user logs in.
 * request: postV1ClinicuserSelfLogin
 * url: postV1ClinicuserSelfLoginURL
 * method: postV1ClinicuserSelfLogin_TYPE
 * raw_url: postV1ClinicuserSelfLogin_RAW_URL
 */
export const postV1ClinicuserSelfLogin = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinicuser/self/login";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1ClinicuserSelfLogin_RAW_URL = function () {
  return "/v1/clinicuser/self/login";
};
export const postV1ClinicuserSelfLogin_TYPE = function () {
  return "post";
};
export const postV1ClinicuserSelfLoginURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinicuser/self/login";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Retrieves a clinic user (clinician)'s record
 * request: getV1ClinicuserByClinicuserid
 * url: getV1ClinicuserByClinicuseridURL
 * method: getV1ClinicuserByClinicuserid_TYPE
 * raw_url: getV1ClinicuserByClinicuserid_RAW_URL
 * @param clinicuserid -
 */
export const getV1ClinicuserByClinicuserid = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinicuser/{clinicuserid}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicuserid}", `${parameters["clinicuserid"]}`);
  if (parameters["clinicuserid"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: clinicuserid")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1ClinicuserByClinicuserid_RAW_URL = function () {
  return "/v1/clinicuser/{clinicuserid}";
};
export const getV1ClinicuserByClinicuserid_TYPE = function () {
  return "get";
};
export const getV1ClinicuserByClinicuseridURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinicuser/{clinicuserid}";
  path = path.replace("{clinicuserid}", `${parameters["clinicuserid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Updates an existing clinic user
 * request: putV1ClinicuserByClinicuserid
 * url: putV1ClinicuserByClinicuseridURL
 * method: putV1ClinicuserByClinicuserid_TYPE
 * raw_url: putV1ClinicuserByClinicuserid_RAW_URL
 * @param clinicuserid -
 * @param updateclinicuser -
 */
export const putV1ClinicuserByClinicuserid = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinicuser/{clinicuserid}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicuserid}", `${parameters["clinicuserid"]}`);
  if (parameters["clinicuserid"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: clinicuserid")
    );
  }
  if (parameters["updateclinicuser"] !== undefined) {
    body = parameters["updateclinicuser"];
  }
  if (parameters["updateclinicuser"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: updateclinicuser")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("put", domain + path, body, queryParameters, form, config);
};
export const putV1ClinicuserByClinicuserid_RAW_URL = function () {
  return "/v1/clinicuser/{clinicuserid}";
};
export const putV1ClinicuserByClinicuserid_TYPE = function () {
  return "put";
};
export const putV1ClinicuserByClinicuseridURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinicuser/{clinicuserid}";
  path = path.replace("{clinicuserid}", `${parameters["clinicuserid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Starts the password reset flow for a user.
 * request: postV1ClinicuserByClinicuseridResetpassword
 * url: postV1ClinicuserByClinicuseridResetpasswordURL
 * method: postV1ClinicuserByClinicuseridResetpassword_TYPE
 * raw_url: postV1ClinicuserByClinicuseridResetpassword_RAW_URL
 * @param clinicuserid -
 */
export const postV1ClinicuserByClinicuseridResetpassword = function (
  parameters = {}
) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinicuser/{clinicuserid}/resetpassword";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{clinicuserid}", `${parameters["clinicuserid"]}`);
  if (parameters["clinicuserid"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: clinicuserid")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1ClinicuserByClinicuseridResetpassword_RAW_URL = function () {
  return "/v1/clinicuser/{clinicuserid}/resetpassword";
};
export const postV1ClinicuserByClinicuseridResetpassword_TYPE = function () {
  return "post";
};
export const postV1ClinicuserByClinicuseridResetpasswordURL = function (
  parameters = {}
) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinicuser/{clinicuserid}/resetpassword";
  path = path.replace("{clinicuserid}", `${parameters["clinicuserid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Enumerates the clinic users you have access to
 * request: getV1Clinicusers
 * url: getV1ClinicusersURL
 * method: getV1Clinicusers_TYPE
 * raw_url: getV1Clinicusers_RAW_URL
 */
export const getV1Clinicusers = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/clinicusers";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1Clinicusers_RAW_URL = function () {
  return "/v1/clinicusers";
};
export const getV1Clinicusers_TYPE = function () {
  return "get";
};
export const getV1ClinicusersURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/clinicusers";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Fetches summarized heartrate data
 * request: postV1DataSummaryByPatientidHr
 * url: postV1DataSummaryByPatientidHrURL
 * method: postV1DataSummaryByPatientidHr_TYPE
 * raw_url: postV1DataSummaryByPatientidHr_RAW_URL
 * @param patientid - Patient ID
 * @param timedivisions - Array of epoch times which deliniate time ranges. Include your min and max desired times. There must be at least 2 values, ideally more. Must be sorted.
 * @param lowmid - Heart rate which divides low and mid ranges
 * @param midhigh - Heart rate which divides mid and high ranges
 */
export const postV1DataSummaryByPatientidHr = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/data/summary/{patientid}/hr";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  if (parameters["timedivisions"] !== undefined) {
    body = parameters["timedivisions"];
  }
  if (parameters["lowmid"] !== undefined) {
    body = parameters["lowmid"];
  }
  if (parameters["midhigh"] !== undefined) {
    body = parameters["midhigh"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1DataSummaryByPatientidHr_RAW_URL = function () {
  return "/v1/data/summary/{patientid}/hr";
};
export const postV1DataSummaryByPatientidHr_TYPE = function () {
  return "post";
};
export const postV1DataSummaryByPatientidHrURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/data/summary/{patientid}/hr";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Fetch the total extent of patient's data.
 * request: getV1DataByPatientidExtents
 * url: getV1DataByPatientidExtentsURL
 * method: getV1DataByPatientidExtents_TYPE
 * raw_url: getV1DataByPatientidExtents_RAW_URL
 * @param patientid -
 */
export const getV1DataByPatientidExtents = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/data/{patientid}/extents";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1DataByPatientidExtents_RAW_URL = function () {
  return "/v1/data/{patientid}/extents";
};
export const getV1DataByPatientidExtents_TYPE = function () {
  return "get";
};
export const getV1DataByPatientidExtentsURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/data/{patientid}/extents";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Fetches patient sensor data.
 * request: getV1DataByPatientidByDatatypes
 * url: getV1DataByPatientidByDatatypesURL
 * method: getV1DataByPatientidByDatatypes_TYPE
 * raw_url: getV1DataByPatientidByDatatypes_RAW_URL
 * @param patientid -
 * @param datatypes - Comma-separated list of data types to fetch. "temperature", "potassium", "hr", "spo2", "hct", "hgb", "hrvraw", and "audio" are the options.
 * @param start - Epoch time of the start of the time range to limit the query to.
 * @param stop - Epoch time of the end of the time range to limit the query to.
 * @param session - A patient session to fetch data for
 * @param streaming - Default false, determines where to query data from
 * @param patchId - Patch we use to search in mongo when in streaming mode
 * @param presign - Default false, determines whether to return presigned urls or
 */
export const getV1DataByPatientidByDatatypes = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/data/{patientid}/{datatypes}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  path = path.replace("{datatypes}", `${parameters["datatypes"]}`);
  if (parameters["datatypes"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: datatypes"));
  }
  if (parameters["start"] !== undefined) {
    queryParameters["start"] = parameters["start"];
  }
  if (parameters["stop"] !== undefined) {
    queryParameters["stop"] = parameters["stop"];
  }
  if (parameters["session"] !== undefined) {
    queryParameters["session"] = parameters["session"];
  }
  if (parameters["streaming"] !== undefined) {
    queryParameters["streaming"] = parameters["streaming"];
  }
  if (parameters["patchId"] !== undefined) {
    queryParameters["patch_id"] = parameters["patchId"];
  }
  if (parameters["presign"] !== undefined) {
    queryParameters["presign"] = parameters["presign"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1DataByPatientidByDatatypes_RAW_URL = function () {
  return "/v1/data/{patientid}/{datatypes}";
};
export const getV1DataByPatientidByDatatypes_TYPE = function () {
  return "get";
};
export const getV1DataByPatientidByDatatypesURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/data/{patientid}/{datatypes}";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  path = path.replace("{datatypes}", `${parameters["datatypes"]}`);
  if (parameters["start"] !== undefined) {
    queryParameters["start"] = parameters["start"];
  }
  if (parameters["stop"] !== undefined) {
    queryParameters["stop"] = parameters["stop"];
  }
  if (parameters["session"] !== undefined) {
    queryParameters["session"] = parameters["session"];
  }
  if (parameters["streaming"] !== undefined) {
    queryParameters["streaming"] = parameters["streaming"];
  }
  if (parameters["patchId"] !== undefined) {
    queryParameters["patch_id"] = parameters["patchId"];
  }
  if (parameters["presign"] !== undefined) {
    queryParameters["presign"] = parameters["presign"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Fetches details of a single patient sensor data record
 * request: getV1DataByPatientidByDatatypeByTimestamp
 * url: getV1DataByPatientidByDatatypeByTimestampURL
 * method: getV1DataByPatientidByDatatypeByTimestamp_TYPE
 * raw_url: getV1DataByPatientidByDatatypeByTimestamp_RAW_URL
 * @param patientid -
 * @param datatype - Single data type to look up, "potassium", "temperature", "hr", "audio", "spo2", "hct", "hrvraw"
 * @param timestamp - Time stamp of the record to find
 */
export const getV1DataByPatientidByDatatypeByTimestamp = function (
  parameters = {}
) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/data/{patientid}/{datatype}/{timestamp}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  path = path.replace("{datatype}", `${parameters["datatype"]}`);
  path = path.replace("{timestamp}", `${parameters["timestamp"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1DataByPatientidByDatatypeByTimestamp_RAW_URL = function () {
  return "/v1/data/{patientid}/{datatype}/{timestamp}";
};
export const getV1DataByPatientidByDatatypeByTimestamp_TYPE = function () {
  return "get";
};
export const getV1DataByPatientidByDatatypeByTimestampURL = function (
  parameters = {}
) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/data/{patientid}/{datatype}/{timestamp}";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  path = path.replace("{datatype}", `${parameters["datatype"]}`);
  path = path.replace("{timestamp}", `${parameters["timestamp"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Moves kits into this environment
 * request: postV1DeviceKitsMove
 * url: postV1DeviceKitsMoveURL
 * method: postV1DeviceKitsMove_TYPE
 * raw_url: postV1DeviceKitsMove_RAW_URL
 * @param kit - Kit ID
 */
export const postV1DeviceKitsMove = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/device/kits/move";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["kit"] !== undefined) {
    body = parameters["kit"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1DeviceKitsMove_RAW_URL = function () {
  return "/v1/device/kits/move";
};
export const postV1DeviceKitsMove_TYPE = function () {
  return "post";
};
export const postV1DeviceKitsMoveURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/device/kits/move";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Searches for devices.
 * request: postV1DeviceSearch
 * url: postV1DeviceSearchURL
 * method: postV1DeviceSearch_TYPE
 * raw_url: postV1DeviceSearch_RAW_URL
 * @param q - Query object
 * @param environment - set to "generic" for generic; any other value searches the current environment
 * @param page - Query offset
 * @param pageSize - Page size default 100
 * @param sort - Column to sort by, defaults to vpn_hostname
 * @param descending - Descending sort; defaults to true
 * @param devicetype - Type of device to search for. kits, hubs, patches, patchboxes
 */
export const postV1DeviceSearch = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/device/search";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["q"] !== undefined) {
    body = parameters["q"];
  }
  if (parameters["environment"] !== undefined) {
    body = parameters["environment"];
  }
  if (parameters["page"] !== undefined) {
    body = parameters["page"];
  }
  if (parameters["pageSize"] !== undefined) {
    body = parameters["pageSize"];
  }
  if (parameters["sort"] !== undefined) {
    body = parameters["sort"];
  }
  if (parameters["descending"] !== undefined) {
    body = parameters["descending"];
  }
  if (parameters["devicetype"] !== undefined) {
    body = parameters["devicetype"];
  }
  if (parameters["devicetype"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: devicetype"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1DeviceSearch_RAW_URL = function () {
  return "/v1/device/search";
};
export const postV1DeviceSearch_TYPE = function () {
  return "post";
};
export const postV1DeviceSearchURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/device/search";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Get hub data including hologram data
 * request: getV1HubsByHubId
 * url: getV1HubsByHubIdURL
 * method: getV1HubsByHubId_TYPE
 * raw_url: getV1HubsByHubId_RAW_URL
 * @param hubId -
 */
export const getV1HubsByHubId = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/hubs/{hub_id}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{hub_id}", `${parameters["hubId"]}`);
  if (parameters["hubId"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: hubId"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1HubsByHubId_RAW_URL = function () {
  return "/v1/hubs/{hub_id}";
};
export const getV1HubsByHubId_TYPE = function () {
  return "get";
};
export const getV1HubsByHubIdURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/hubs/{hub_id}";
  path = path.replace("{hub_id}", `${parameters["hubId"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Get latest logs for a patch
 * request: getV1HubsByHubIdPatchSyncLogLatest
 * url: getV1HubsByHubIdPatchSyncLogLatestURL
 * method: getV1HubsByHubIdPatchSyncLogLatest_TYPE
 * raw_url: getV1HubsByHubIdPatchSyncLogLatest_RAW_URL
 * @param hubId -
 */
export const getV1HubsByHubIdPatchSyncLogLatest = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/hubs/{hub_id}/patch-sync-log/latest";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["hubId"] !== undefined) {
    body = parameters["hubId"];
  }
  if (parameters["hubId"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: hubId"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1HubsByHubIdPatchSyncLogLatest_RAW_URL = function () {
  return "/v1/hubs/{hub_id}/patch-sync-log/latest";
};
export const getV1HubsByHubIdPatchSyncLogLatest_TYPE = function () {
  return "get";
};
export const getV1HubsByHubIdPatchSyncLogLatestURL = function (
  parameters = {}
) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/hubs/{hub_id}/patch-sync-log/latest";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Creates a supplies order record
 * request: postV1Order
 * url: postV1OrderURL
 * method: postV1Order_TYPE
 * raw_url: postV1Order_RAW_URL
 * @param createorder -
 */
export const postV1Order = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/order";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["createorder"] !== undefined) {
    body = parameters["createorder"];
  }
  if (parameters["createorder"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: createorder")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1Order_RAW_URL = function () {
  return "/v1/order";
};
export const postV1Order_TYPE = function () {
  return "post";
};
export const postV1OrderURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/order";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Allows you to send notifications independent of lifecycle hooks
 * request: postV1Notifications
 * url: postV1NotificationsURL
 * method: postV1Notifications_TYPE
 * raw_url: postV1Notifications_RAW_URL
 * @param msgkind -
 * @param clinicianonly -
 * @param acceptableroutes -
 */
export const postV1Notifications = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/notifications";
  let body;
  let queryParameters = {};
  let form = {};

  console.log(parameters);
  body = {};
  if (parameters["msgkind"] !== undefined) {
    body["msgkind"] = parameters["msgkind"];
  }
  if (parameters["msgkind"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: msgkind"));
  }
  if (parameters["clinicianonly"] !== undefined) {
    body["clinicianonly"] = parameters["clinicianonly"];
  }
  if (parameters["acceptableroutes"] !== undefined) {
    body["acceptableroutes"] = parameters["acceptableroutes"];
  }
  if (parameters["patient_id"] !== undefined) {
    body["patient_id"] = parameters["patient_id"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  console.log(body);
  return request("post", domain + path, body, queryParameters, form, config);
};
/**
 * Retrieves a supplies order record
 * request: getV1OrderByOrderid
 * url: getV1OrderByOrderidURL
 * method: getV1OrderByOrderid_TYPE
 * raw_url: getV1OrderByOrderid_RAW_URL
 * @param orderid - Order UUID
 */
export const getV1OrderByOrderid = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/order/{orderid}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{orderid}", `${parameters["orderid"]}`);
  if (parameters["orderid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: orderid"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1OrderByOrderid_RAW_URL = function () {
  return "/v1/order/{orderid}";
};
export const getV1OrderByOrderid_TYPE = function () {
  return "get";
};
export const getV1OrderByOrderidURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/order/{orderid}";
  path = path.replace("{orderid}", `${parameters["orderid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * <br/>Omitting the patient and clinic ID will search for<br/>orders which are not attached to a patient or clinic,<br/>unless the all flag is set.<br/>
 * request: getV1Orders
 * url: getV1OrdersURL
 * method: getV1Orders_TYPE
 * raw_url: getV1Orders_RAW_URL
 * @param patientid - Patient ID to search for
 * @param clinicid - Clinic ID to search for
 * @param  - Order ID to fetch
 * @param all - Specifically pulls all orders. May cause problems.
 */
export const getV1Orders = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/orders";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["patientid"] !== undefined) {
    queryParameters["patientid"] = parameters["patientid"];
  }
  if (parameters["clinicid"] !== undefined) {
    queryParameters["clinicid"] = parameters["clinicid"];
  }
  if (parameters[""] !== undefined) {
    queryParameters[""] = parameters[""];
  }
  if (parameters["all"] !== undefined) {
    queryParameters["all"] = parameters["all"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1Orders_RAW_URL = function () {
  return "/v1/orders";
};
export const getV1Orders_TYPE = function () {
  return "get";
};
export const getV1OrdersURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/orders";
  if (parameters["patientid"] !== undefined) {
    queryParameters["patientid"] = parameters["patientid"];
  }
  if (parameters["clinicid"] !== undefined) {
    queryParameters["clinicid"] = parameters["clinicid"];
  }
  if (parameters[""] !== undefined) {
    queryParameters[""] = parameters[""];
  }
  if (parameters["all"] !== undefined) {
    queryParameters["all"] = parameters["all"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Get information from patch(es)
 * request: getV1PatchesByPatchIds
 * url: getV1PatchesByPatchIdsURL
 * method: getV1PatchesByPatchIds_TYPE
 * raw_url: getV1PatchesByPatchIds_RAW_URL
 * @param patchIds -
 */
export const getV1PatchesByPatchIds = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patches/{patch_ids}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patch_ids}", `${parameters["patchIds"]}`);
  if (parameters["patchIds"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patchIds"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1PatchesByPatchIds_RAW_URL = function () {
  return "/v1/patches/{patch_ids}";
};
export const getV1PatchesByPatchIds_TYPE = function () {
  return "get";
};
export const getV1PatchesByPatchIdsURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patches/{patch_ids}";
  path = path.replace("{patch_ids}", `${parameters["patchIds"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Retrieves a list of patch modes
 * request: getV1Patchmodes
 * url: getV1PatchmodesURL
 * method: getV1Patchmodes_TYPE
 * raw_url: getV1Patchmodes_RAW_URL
 */
export const getV1Patchmodes = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patchmodes";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1Countries = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/countries";
  let body;
  let queryParameters = {};
  let form = {};

  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1Patchmodes_RAW_URL = function () {
  return "/v1/patchmodes";
};
export const getV1Patchmodes_TYPE = function () {
  return "get";
};
export const getV1PatchmodesURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patchmodes";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/><br/>Some other fields are ignored/replaced by the backend, like "added", "addedby"<br/>and "status".<br/>
 * request: postV1Patient
 * url: postV1PatientURL
 * method: postV1Patient_TYPE
 * raw_url: postV1Patient_RAW_URL
 * @param createpatient -
 */
export const postV1Patient = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patient";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["createpatient"] !== undefined) {
    body = parameters["createpatient"];
  }
  if (parameters["createpatient"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: createpatient")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1Patient_RAW_URL = function () {
  return "/v1/patient";
};
export const postV1Patient_TYPE = function () {
  return "post";
};
export const postV1PatientURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patient";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Retrieves a patient's record
 * request: getV1PatientByPatientid
 * url: getV1PatientByPatientidURL
 * method: getV1PatientByPatientid_TYPE
 * raw_url: getV1PatientByPatientid_RAW_URL
 * @param patientid -
 */
export const getV1PatientByPatientid = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patient/{patientid}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1PatientByPatientid_RAW_URL = function () {
  return "/v1/patient/{patientid}";
};
export const getV1PatientByPatientid_TYPE = function () {
  return "get";
};
export const getV1PatientByPatientidURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patient/{patientid}";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Updates an existing patient
 * request: putV1PatientByPatientid
 * url: putV1PatientByPatientidURL
 * method: putV1PatientByPatientid_TYPE
 * raw_url: putV1PatientByPatientid_RAW_URL
 * @param patientid -
 * @param updatepatient -
 */
export const putV1PatientByPatientid = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patient/{patientid}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  if (parameters["updatepatient"] !== undefined) {
    body = parameters["updatepatient"];
  }
  if (parameters["updatepatient"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: updatepatient")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("put", domain + path, body, queryParameters, form, config);
};
export const putV1PatientByPatientid_RAW_URL = function () {
  return "/v1/patient/{patientid}";
};
export const putV1PatientByPatientid_TYPE = function () {
  return "put";
};
export const putV1PatientByPatientidURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patient/{patientid}";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Ends a patient's monitoring period, now.
 * request: putV1PatientByPatientidEndmonitoring
 * url: putV1PatientByPatientidEndmonitoringURL
 * method: putV1PatientByPatientidEndmonitoring_TYPE
 * raw_url: putV1PatientByPatientidEndmonitoring_RAW_URL
 * @param patientid -
 * @param reason - TODO just a placeholder. do we need anything here?
 */
export const putV1PatientByPatientidEndmonitoring = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patient/{patientid}/endmonitoring";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  if (parameters["reason"] !== undefined) {
    body = parameters["reason"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("put", domain + path, body, queryParameters, form, config);
};
export const putV1PatientByPatientidEndmonitoring_RAW_URL = function () {
  return "/v1/patient/{patientid}/endmonitoring";
};
export const putV1PatientByPatientidEndmonitoring_TYPE = function () {
  return "put";
};
export const putV1PatientByPatientidEndmonitoringURL = function (
  parameters = {}
) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patient/{patientid}/endmonitoring";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Extends a patient's monitoring period
 * request: postV1PatientByPatientidExtendmonitoring
 * url: postV1PatientByPatientidExtendmonitoringURL
 * method: postV1PatientByPatientidExtendmonitoring_TYPE
 * raw_url: postV1PatientByPatientidExtendmonitoring_RAW_URL
 * @param patientid -
 * @param increment - An integer number of monitoring units by which to extend the monitoring duration.
 */
export const postV1PatientByPatientidExtendmonitoring = function (
  parameters = {}
) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patient/{patientid}/extendmonitoring";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  if (parameters["increment"] !== undefined) {
    body = parameters["increment"];
  }
  if (parameters["increment"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: increment"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1PatientByPatientidExtendmonitoring_RAW_URL = function () {
  return "/v1/patient/{patientid}/extendmonitoring";
};
export const postV1PatientByPatientidExtendmonitoring_TYPE = function () {
  return "post";
};
export const postV1PatientByPatientidExtendmonitoringURL = function (
  parameters = {}
) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patient/{patientid}/extendmonitoring";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Get details of a specific patient session.
 * request: getV1PatientByPatientidSessionBySessionid
 * url: getV1PatientByPatientidSessionBySessionidURL
 * method: getV1PatientByPatientidSessionBySessionid_TYPE
 * raw_url: getV1PatientByPatientidSessionBySessionid_RAW_URL
 * @param patientid -
 * @param sessionid -
 */
export const getV1PatientByPatientidSessionBySessionid = function (
  parameters = {}
) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patient/{patientid}/session/{sessionid}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  path = path.replace("{sessionid}", `${parameters["sessionid"]}`);
  if (parameters["sessionid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: sessionid"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1PatientByPatientidSessionBySessionid_RAW_URL = function () {
  return "/v1/patient/{patientid}/session/{sessionid}";
};
export const getV1PatientByPatientidSessionBySessionid_TYPE = function () {
  return "get";
};
export const getV1PatientByPatientidSessionBySessionidURL = function (
  parameters = {}
) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patient/{patientid}/session/{sessionid}";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  path = path.replace("{sessionid}", `${parameters["sessionid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Updates a patient's session
 * request: putV1PatientByPatientidSessionBySessionid
 * url: putV1PatientByPatientidSessionBySessionidURL
 * method: putV1PatientByPatientidSessionBySessionid_TYPE
 * raw_url: putV1PatientByPatientidSessionBySessionid_RAW_URL
 * @param patientid -
 * @param sessionid -
 * @param updatesession -
 */
export const putV1PatientByPatientidSessionBySessionid = function (
  parameters = {}
) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patient/{patientid}/session/{sessionid}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  path = path.replace("{sessionid}", `${parameters["sessionid"]}`);
  if (parameters["sessionid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: sessionid"));
  }
  if (parameters["updatesession"] !== undefined) {
    body = parameters["updatesession"];
  }
  if (parameters["updatesession"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: updatesession")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("put", domain + path, body, queryParameters, form, config);
};
export const putV1PatientByPatientidSessionBySessionid_RAW_URL = function () {
  return "/v1/patient/{patientid}/session/{sessionid}";
};
export const putV1PatientByPatientidSessionBySessionid_TYPE = function () {
  return "put";
};
export const putV1PatientByPatientidSessionBySessionidURL = function (
  parameters = {}
) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patient/{patientid}/session/{sessionid}";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  path = path.replace("{sessionid}", `${parameters["sessionid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Lists all of a patient's sessions.
 * request: getV1PatientByPatientidSessions
 * url: getV1PatientByPatientidSessionsURL
 * method: getV1PatientByPatientidSessions_TYPE
 * raw_url: getV1PatientByPatientidSessions_RAW_URL
 * @param patientid -
 */
export const getV1PatientByPatientidSessions = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patient/{patientid}/sessions";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1PatientByPatientidSessions_RAW_URL = function () {
  return "/v1/patient/{patientid}/sessions";
};
export const getV1PatientByPatientidSessions_TYPE = function () {
  return "get";
};
export const getV1PatientByPatientidSessionsURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patient/{patientid}/sessions";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * <br/>This is separate from standard patient updating, as it should be a rare operation, not<br/>performed in conjunction with other patient updates, that may require expensive/slow<br/>checks. Also, it has a "reset" mechanism, which doesn't make any sense for other fields.<br/>
 * request: putV1PatientByPatientidSubjectid
 * url: putV1PatientByPatientidSubjectidURL
 * method: putV1PatientByPatientidSubjectid_TYPE
 * raw_url: putV1PatientByPatientidSubjectid_RAW_URL
 * @param patientid -
 * @param setsubjectid - New subject ID to use. Set of valid values may be limited.
 * @param resetsubjectid -
 */
export const putV1PatientByPatientidSubjectid = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patient/{patientid}/subjectid";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  if (parameters["setsubjectid"] !== undefined) {
    body = parameters["setsubjectid"];
  }
  if (parameters["resetsubjectid"] !== undefined) {
    body = parameters["resetsubjectid"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("put", domain + path, body, queryParameters, form, config);
};
export const putV1PatientByPatientidSubjectid_RAW_URL = function () {
  return "/v1/patient/{patientid}/subjectid";
};
export const putV1PatientByPatientidSubjectid_TYPE = function () {
  return "put";
};
export const putV1PatientByPatientidSubjectidURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patient/{patientid}/subjectid";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * This expects that you're only providing one of kitgsn, patchgsn, patchboxgsn or hubgsn<br/>at any given time.<br/>
 * request: postV1PatientByPatientidSupplies
 * url: postV1PatientByPatientidSuppliesURL
 * method: postV1PatientByPatientidSupplies_TYPE
 * raw_url: postV1PatientByPatientidSupplies_RAW_URL
 * @param patientid -
 * @param supplies -
 */
export const postV1PatientByPatientidSupplies = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patient/{patientid}/supplies";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  if (parameters["supplies"] !== undefined) {
    body = parameters["supplies"];
  }
  if (parameters["supplies"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: supplies"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1PatientByPatientidSupplies_RAW_URL = function () {
  return "/v1/patient/{patientid}/supplies";
};
export const postV1PatientByPatientidSupplies_TYPE = function () {
  return "post";
};
export const postV1PatientByPatientidSuppliesURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patient/{patientid}/supplies";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Logs the current user as having seen the patient's record.
 * request: postV1PatientByPatientidViewed
 * url: postV1PatientByPatientidViewedURL
 * method: postV1PatientByPatientidViewed_TYPE
 * raw_url: postV1PatientByPatientidViewed_RAW_URL
 * @param patientid -
 */
export const postV1PatientByPatientidViewed = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patient/{patientid}/viewed";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters["patientid"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientid"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1PatientByPatientidViewed_RAW_URL = function () {
  return "/v1/patient/{patientid}/viewed";
};
export const postV1PatientByPatientidViewed_TYPE = function () {
  return "post";
};
export const postV1PatientByPatientidViewedURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patient/{patientid}/viewed";
  path = path.replace("{patientid}", `${parameters["patientid"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Enumerates the patients you have access to
 * request: getV1Patients
 * url: getV1PatientsURL
 * method: getV1Patients_TYPE
 * raw_url: getV1Patients_RAW_URL
 * @param limit - Number of records to return,
 * @param skip - Number of records to skip over.
 * @param sort - Comma separated list of field names to sort by
 * @param dir - Comma separated list of ASC or DESC, corresponding to the fields in sort. If dir is shorter than sort, the last direction will be used for all remaining fields.
 * @param expectedmaxpatientid - The largest expected patient ID, so that we can abort some of the expensive work if a patient has been added mid-listing.
 */
export const getV1Patients = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patients";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["limit"] !== undefined) {
    queryParameters["limit"] = parameters["limit"];
  }
  if (parameters["skip"] !== undefined) {
    queryParameters["skip"] = parameters["skip"];
  }
  if (parameters["sort"] !== undefined) {
    queryParameters["sort"] = parameters["sort"];
  }
  if (parameters["dir"] !== undefined) {
    queryParameters["dir"] = parameters["dir"];
  }
  if (parameters["expectedmaxpatientid"] !== undefined) {
    queryParameters["expectedmaxpatientid"] =
      parameters["expectedmaxpatientid"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1Patients_RAW_URL = function () {
  return "/v1/patients";
};
export const getV1Patients_TYPE = function () {
  return "get";
};
export const getV1PatientsURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patients";
  if (parameters["limit"] !== undefined) {
    queryParameters["limit"] = parameters["limit"];
  }
  if (parameters["skip"] !== undefined) {
    queryParameters["skip"] = parameters["skip"];
  }
  if (parameters["sort"] !== undefined) {
    queryParameters["sort"] = parameters["sort"];
  }
  if (parameters["dir"] !== undefined) {
    queryParameters["dir"] = parameters["dir"];
  }
  if (parameters["expectedmaxpatientid"] !== undefined) {
    queryParameters["expectedmaxpatientid"] =
      parameters["expectedmaxpatientid"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Bulk upload of patients
 * request: postV1PatientsBulk
 * url: postV1PatientsBulkURL
 * method: postV1PatientsBulk_TYPE
 * raw_url: postV1PatientsBulk_RAW_URL
 * @param file - CSV file of patients to create
 */
export const postV1PatientsBulk = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patients/bulk";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["file"] !== undefined) {
    form["file"] = parameters["file"];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1PatientsBulk_RAW_URL = function () {
  return "/v1/patients/bulk";
};
export const postV1PatientsBulk_TYPE = function () {
  return "post";
};
export const postV1PatientsBulkURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patients/bulk";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Remove patch from patient
 * request: deleteV1PatientsByPatientIdPatchesByPatchId
 * url: deleteV1PatientsByPatientIdPatchesByPatchIdURL
 * method: deleteV1PatientsByPatientIdPatchesByPatchId_TYPE
 * raw_url: deleteV1PatientsByPatientIdPatchesByPatchId_RAW_URL
 * @param patientId -
 * @param patchId -
 */
export const deleteV1PatientsByPatientIdPatchesByPatchId = function (
  parameters = {}
) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/patients/{patient_id}/patches/{patch_id}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{patient_id}", `${parameters["patientId"]}`);
  if (parameters["patientId"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patientId"));
  }
  path = path.replace("{patch_id}", `${parameters["patchId"]}`);
  if (parameters["patchId"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patchId"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("delete", domain + path, body, queryParameters, form, config);
};
export const deleteV1PatientsByPatientIdPatchesByPatchId_RAW_URL = function () {
  return "/v1/patients/{patient_id}/patches/{patch_id}";
};
export const deleteV1PatientsByPatientIdPatchesByPatchId_TYPE = function () {
  return "delete";
};
export const deleteV1PatientsByPatientIdPatchesByPatchIdURL = function (
  parameters = {}
) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/patients/{patient_id}/patches/{patch_id}";
  path = path.replace("{patient_id}", `${parameters["patientId"]}`);
  path = path.replace("{patch_id}", `${parameters["patchId"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 *     The id in the input is ignored, the assigned id will be returned in the output.<br/>    It is possible the support user is successfully created, but the client never receives<br/>    the response. In the event of an error, check for existence before continuing.<br/>-<br/>    You can only create support users in clinics attached to the "default" site / user pool.<br/>
 * request: postV1Phisupportuser
 * url: postV1PhisupportuserURL
 * method: postV1Phisupportuser_TYPE
 * raw_url: postV1Phisupportuser_RAW_URL
 * @param createsupportuser -
 */
export const postV1Phisupportuser = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/phisupportuser";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["createsupportuser"] !== undefined) {
    body = parameters["createsupportuser"];
  }
  if (parameters["createsupportuser"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: createsupportuser")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1Phisupportuser_RAW_URL = function () {
  return "/v1/phisupportuser";
};
export const postV1Phisupportuser_TYPE = function () {
  return "post";
};
export const postV1PhisupportuserURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/phisupportuser";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Used to connect to rabbitmq and receive live data<br/>
 * request: postV1RabbitmqUsers
 * url: postV1RabbitmqUsersURL
 * method: postV1RabbitmqUsers_TYPE
 * raw_url: postV1RabbitmqUsers_RAW_URL
 * @param patchId -
 */
export const postV1RabbitmqUsers = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/rabbitmq/users";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["patchId"] !== undefined) {
    body = parameters["patchId"];
  }
  if (parameters["patchId"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: patchId"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1RabbitmqUsers_RAW_URL = function () {
  return "/v1/rabbitmq/users";
};
export const postV1RabbitmqUsers_TYPE = function () {
  return "post";
};
export const postV1RabbitmqUsersURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/rabbitmq/users";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Deletes rabbitmq user to cleanup after use
 * request: deleteV1RabbitmqUsersByUsername
 * url: deleteV1RabbitmqUsersByUsernameURL
 * method: deleteV1RabbitmqUsersByUsername_TYPE
 * raw_url: deleteV1RabbitmqUsersByUsername_RAW_URL
 * @param username -
 */
export const deleteV1RabbitmqUsersByUsername = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/rabbitmq/users/{username}";
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace("{username}", `${parameters["username"]}`);
  if (parameters["username"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: username"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("delete", domain + path, body, queryParameters, form, config);
};
export const deleteV1RabbitmqUsersByUsername_RAW_URL = function () {
  return "/v1/rabbitmq/users/{username}";
};
export const deleteV1RabbitmqUsersByUsername_TYPE = function () {
  return "delete";
};
export const deleteV1RabbitmqUsersByUsernameURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/rabbitmq/users/{username}";
  path = path.replace("{username}", `${parameters["username"]}`);
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Creates a batch of patient sessions.
 * request: postV1Session
 * url: postV1SessionURL
 * method: postV1Session_TYPE
 * raw_url: postV1Session_RAW_URL
 * @param sessions -
 */
export const postV1Session = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/session";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["sessions"] !== undefined) {
    body = parameters["sessions"];
  }
  if (parameters["sessions"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: sessions"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1Session_RAW_URL = function () {
  return "/v1/session";
};
export const postV1Session_TYPE = function () {
  return "post";
};
export const postV1SessionURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/session";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Takes a set of instructions on how to perform one or many splits.
 * request: postV1SessionSplit
 * url: postV1SessionSplitURL
 * method: postV1SessionSplit_TYPE
 * raw_url: postV1SessionSplit_RAW_URL
 * @param splits -
 */
export const postV1SessionSplit = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/session/split";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["splits"] !== undefined) {
    body = parameters["splits"];
  }
  if (parameters["splits"] === undefined) {
    return Promise.reject(new Error("Missing required  parameter: splits"));
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1SessionSplit_RAW_URL = function () {
  return "/v1/session/split";
};
export const postV1SessionSplit_TYPE = function () {
  return "post";
};
export const postV1SessionSplitURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/session/split";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * support portal frontend.<br/>
 * request: getV1Supportinfo
 * url: getV1SupportinfoURL
 * method: getV1Supportinfo_TYPE
 * raw_url: getV1Supportinfo_RAW_URL
 */
export const getV1Supportinfo = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/supportinfo";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1Supportinfo_RAW_URL = function () {
  return "/v1/supportinfo";
};
export const getV1Supportinfo_TYPE = function () {
  return "get";
};
export const getV1SupportinfoURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/supportinfo";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 *     The id in the input is ignored, the assigned id will be returned in the output.<br/>    It is possible the support user is successfully created, but the client never receives<br/>    the response. In the event of an error, check for existence before continuing.<br/>-<br/>    You can only create support users in clinics attached to the "default" site / user pool.<br/>
 * request: postV1Supportuser
 * url: postV1SupportuserURL
 * method: postV1Supportuser_TYPE
 * raw_url: postV1Supportuser_RAW_URL
 * @param createsupportuser -
 */
export const postV1Supportuser = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/supportuser";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters["createsupportuser"] !== undefined) {
    body = parameters["createsupportuser"];
  }
  if (parameters["createsupportuser"] === undefined) {
    return Promise.reject(
      new Error("Missing required  parameter: createsupportuser")
    );
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("post", domain + path, body, queryParameters, form, config);
};
export const postV1Supportuser_RAW_URL = function () {
  return "/v1/supportuser";
};
export const postV1Supportuser_TYPE = function () {
  return "post";
};
export const postV1SupportuserURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/supportuser";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * <br/>Only available to site admins and customer support users. Will fill out the<br/>groups property on the user records.<br/>
 * request: getV1Supportusers
 * url: getV1SupportusersURL
 * method: getV1Supportusers_TYPE
 * raw_url: getV1Supportusers_RAW_URL
 */
export const getV1Supportusers = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/supportusers";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1Supportusers_RAW_URL = function () {
  return "/v1/supportusers";
};
export const getV1Supportusers_TYPE = function () {
  return "get";
};
export const getV1SupportusersURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/supportusers";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Provides a bit of user-readable information about the system.
 * request: getV1Systeminfo
 * url: getV1SysteminfoURL
 * method: getV1Systeminfo_TYPE
 * raw_url: getV1Systeminfo_RAW_URL
 */
export const getV1Systeminfo = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/systeminfo";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1Systeminfo_RAW_URL = function () {
  return "/v1/systeminfo";
};
export const getV1Systeminfo_TYPE = function () {
  return "get";
};
export const getV1SysteminfoURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/systeminfo";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
/**
 * Returns the user's user ID, from the session information.
 * request: getV1Whoami
 * url: getV1WhoamiURL
 * method: getV1Whoami_TYPE
 * raw_url: getV1Whoami_RAW_URL
 */
export const getV1Whoami = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/whoami";
  let body;
  let queryParameters = {};
  let form = {};
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  return request("get", domain + path, body, queryParameters, form, config);
};
export const getV1Whoami_RAW_URL = function () {
  return "/v1/whoami";
};
export const getV1Whoami_TYPE = function () {
  return "get";
};
export const getV1WhoamiURL = function (parameters = {}) {
  let queryParameters = {};
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  let path = "/v1/whoami";
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] =
        parameters.$queryParameters[parameterName];
    });
  }
  let keys = Object.keys(queryParameters);
  return (
    domain +
    path +
    (keys.length > 0
      ? "?" +
        keys
          .map((key) => key + "=" + encodeURIComponent(queryParameters[key]))
          .join("&")
      : "")
  );
};
