<template>
  <div class="date-select">
    <b-form-select
      :id="`${type}-${cuuid}-${index}`"
      class="form-select"
      :value="selected"
      :options="options"
      :tabindex="tabindex"
      @change="change"
    ></b-form-select>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "DateSelect",
  props: {
    type: {
      required: true,
    },
    selected: {
      required: true,
    },
    options: {
      required: true,
    },
    tabindex: {
      required: true,
    },
    index: {
      required: true,
    },
  },
  data() {
    return {
      cuuid: uuidv4(),
    };
  },
  methods: {
    change(value) {
      this.$emit(`update`, { type: this.type, value });
    },
  },
};
</script>

<style scoped>
.form-select {
  padding-left: 0px;
}
.date-select {
  flex: 1;
}
</style>
