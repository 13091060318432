import PatientAdd from "@/shared/pages/PatientAdd.vue";
import PatientEdit from "@/shared/pages/PatientEdit.vue";
import PatientListClinic from "@/shared/pages/PatientListClinic.vue";
import PatientDetail from "@/shared/pages/PatientDetail.vue";
import PatientThresholds from "@/shared/pages/PatientThresholds.vue";

export const sharedRoutes = [
  {
    path: "/patient-detail/:clinicId/:patientId",
    name: "patient-detail",
    component: PatientDetail,
    meta: {
      requiresAuth: true,
      showNavigation: true,
    },
  },
  {
    path: "/patient-list/:clinicId",
    name: "patient-list",
    component: PatientListClinic,
    meta: {
      requiresAuth: true,
      showNavigation: true,
    },
  },
  {
    path: "/clinics/:clinicId/patients/add",
    name: "add-patient",
    component: PatientAdd,
    meta: {
      requiresAuth: true,
      showNavigation: true,
    },
  },
  {
    path: "/clinics/:clinicId/patients/:patientId/edit",
    name: "edit-patient",
    component: PatientEdit,
    meta: {
      requiresAuth: true,
      showNavigation: true,
    },
  },
  {
    path: "/clinics/:clinicId/patients/:patientId/thresholds",
    name: "thresholds-patient",
    component: PatientThresholds,
    meta: {
      requiresAuth: true,
      showNavigation: true,
    },
  },
];
