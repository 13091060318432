<template>
  <div ref="chartdiv" :class="chartClass"></div>
</template>

<script>
import ChartMixin from "@/shared/mixins/ChartMixin";
import {
  Bullet,
  Circle,
  color,
  Container,
  Graphics,
  p50,
  Template,
  Tooltip,
  Root,
} from "@amcharts/amcharts5";
import {
  AxisRendererY,
  LineSeries,
  ValueAxis,
  XYChart,
  XYCursor,
} from "@amcharts/amcharts5/xy";
import {
  calcFixedPosition,
  chartProps,
} from "@/shared/components/Charts/chartCommon";

export default {
  name: "HHChart",
  mixins: [ChartMixin],
  props: {
    ...chartProps,
    avg24hValue: {
      type: Number,
      default: null,
    },
    chartType: {
      validator: function (value) {
        return ["hct", "hgb"].indexOf(value) !== -1;
      },
      required: true,
    },
  },
  data() {
    return {
      chartLoaded: false,
      label: null,
      unitsRange: null,
      unitsRangeDisplay: null,
      ALIO_BLUE: color("#007BFF"),
      rangeUnitMapping: {
        hct: {
          "%": this.$t("chart.labelRangeHematocrit"),
        },
        hgb: {
          "g/dL": this.$t("chart.labelRangeHemoglobin"),
        },
      },
    };
  },
  computed: {
    chartClass() {
      if (!this.chartLoaded) return "hidden-chart";
      return "chart";
    },
    padFactor() {
      if (this.chartType === "hct") {
        return 0.5;
      } else {
        return 0.25;
      }
    },
    paddedMaxRange() {
      return this.chartData?.maxrange - this.padFactor;
    },
    paddedMinRange() {
      return this.chartData?.minrange + this.padFactor;
    },
  },
  watch: {
    chartData: function () {
      this.destroyRoot();
      this.createChart();
    },
  },
  mounted() {
    this.createChart();
  },

  beforeDestroy() {
    this.destroyRoot();
  },
  methods: {
    destroyRoot() {
      if (this.root) {
        this.root.dispose();
      }
    },
    createChart() {
      this.unitsRange = this.chartData.rangeunits;
      this.unitsRangeDisplay = this.chartData.rangedisplayunits
        ? this.chartData.rangedisplayunits
        : this.chartData.rangeunits;
      this.label =
        this.rangeUnitMapping[this.chartType][this.unitsRangeDisplay];

      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      this.root = Root.new(this.$refs.chartdiv);

      this.root.setThemes([this.getTheme(this.root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      const chart = this.root.container.children.push(
        XYChart.new(this.root, {
          maxTooltipDistance: 0,
          paddingRight: 0,
        })
      );
      chart.zoomOutButton.set("forceHidden", true);

      const yAxis = this.setupYAxis(chart, this.root);
      const xAxis = this.setupXAxis(
        chart,
        this.root,
        this.upperBound,
        this.lowerBound,
        this.lowerBound
      );
      this.setupAxisRanges(yAxis);

      const series = this.setupSeries(chart, this.root, xAxis, yAxis);
      this.createSeriesRanges(yAxis, series);

      this.setupHCTAndHGBBullets(this.root, series);
      const avgdata = this.setup24hAverageValueSeries(
        chart,
        this.root,
        xAxis,
        yAxis
      );

      if (avgdata != null) {
        const avgSeries = chart.series.push(
          LineSeries.new(this.root, {
            name: "24h Average",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
          })
        );
        avgSeries.data.setAll(avgdata);

        chart.set(
          "cursor",
          XYCursor.new(this.root, {
            xAxis,
            snapToSeries: [avgSeries],
          })
        );
        avgSeries.appear();
        const avgDataItem = yAxis.makeDataItem({
          value: this.calculateAvgLabelPosition(),
        });
        let avgLabel = yAxis.createAxisRange(avgDataItem);
        avgLabel.get("grid").set("strokeOpacity", 0);
      }

      series.data.setAll(this.setupData());

      const cursorSeries = [series];

      if (this.events.length > 0) {
        const fixedPosition = calcFixedPosition(this.chartData);

        const dialysisEvents = this.setupDialysisEvents(fixedPosition);
        cursorSeries.push(
          this.addDialysisEventsSeries(
            this.root,
            chart,
            xAxis,
            yAxis,
            dialysisEvents
          )
        );
      }

      chart.set(
        "cursor",
        XYCursor.new(this.root, {
          xAxis,
          snapToSeries: cursorSeries,
        })
      );
      series.appear(1000);
      chart.appear(1000, 100);

      this.chartLoaded = true;
    },
    setupHCTAndHGBBullets(root, series) {
      const bulletTemplate = Template.new(root, {});

      // we renamed this to hb but it's not renamed everywhere so this is the solution
      let type = this.chartType;
      if (this.chartType === "hgb") {
        type = "hb";
      }

      bulletTemplate.events.on("click", (ev) => {
        this.clickHandler(type, ev);
      });
      const bullet = series.bullets.push((root) => {
        const container = Container.new(
          root,
          {
            centerX: p50,
            centerY: p50,
          },
          bulletTemplate
        );

        container.states.create("hover", {}).setAll({
          scale: 1.3,
        });

        const circle = Circle.new(root, {
          radius: 6,
          strokeWidth: 2,
          stroke: this.ALIO_PURPLE,
          fill: this.ALIO_PURPLE,
        });

        circle.adapters.add("fill", (fill, target) => {
          if (!target.dataItem) {
            return fill;
          }
          if (
            this.withinGoodRange(target?.dataItem.get("valueY"), this.chartData)
          ) {
            return this.ALIO_SUCCESS_GREEN;
          } else {
            return fill;
          }
        });
        circle.adapters.add("stroke", (stroke, target) => {
          if (!target.dataItem) {
            return stroke;
          }
          if (
            this.withinGoodRange(target?.dataItem.get("valueY"), this.chartData)
          ) {
            return this.ALIO_SUCCESS_GREEN;
          } else {
            return stroke;
          }
        });

        container.children.push(circle);

        const downArrow = Graphics.new(root, {
          stroke: color("#ffffff"),
          strokeWidth: 2,
          strokeOpacity: 1,
          draw: function (display) {
            display.lineTo(-2, -1);
            display.lineTo(0, 1);
            display.lineTo(2, -1);
          },
        });

        downArrow.adapters.add("rotation", (rotation, target) => {
          if (!target.dataItem) {
            return rotation;
          }
          if (target.dataItem.get("valueY") === this.paddedMaxRange) {
            return 180;
          } else {
            return 0;
          }
        });

        downArrow.adapters.add("strokeOpacity", (strokeOpacity, target) => {
          if (!target.dataItem) {
            return strokeOpacity;
          }
          if (
            target.dataItem.get("valueY") !== this.paddedMaxRange &&
            target.dataItem.get("valueY") !== this.paddedMinRange
          ) {
            return 0;
          } else {
            return strokeOpacity;
          }
        });

        container.children.push(downArrow);

        return Bullet.new(root, {
          sprite: container,
        });
      });

      return bullet;
    },
    setup24hAverageValueSeries(chart, root, xAxis, yAxis) {
      if (this.avg24hValue === null) {
        return null;
      }

      const series = chart.series.push(
        LineSeries.new(root, {
          name: "Average Value",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          stroke: this.ALIO_BLUE,
          strokeWidth: 2,
          tooltip: Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: this.get24hAverageTooltipText(),
          }),
        })
      );
      // console.log("text:",this.get24hAverageTooltipText());
      const data = [
        { date: this.lowerBound * 1000, value: this.avg24hValue },
        { date: this.upperBound * 1000, value: this.avg24hValue },
      ];
      series.data.setAll(data);
      // console.log("data:", data);
      // console.log("series:", series);
      return series;
    },

    calculateAvgLabelPosition() {
      const lowerBound = this.chartData?.goodrangefloor;
      const upperBound = this.chartData?.goodrangeceiling;
      const range = upperBound - lowerBound;
      const padding = range * 0.25;
      const minPosition = lowerBound + padding;
      const maxPosition = upperBound - padding;

      if (this.avg24hValue < minPosition) {
        return minPosition;
      } else if (this.avg24hValue > maxPosition) {
        return maxPosition;
      } else {
        return this.avg24hValue;
      }
    },
    get24hAverageTooltipText() {
      return `Date Range Avg: ${this.avg24hValue.toFixed(
        1
      )}${this.getUnitsString(this.unitsRangeDisplay)}`;
    },
    createSeriesRanges(yAxis, series) {
      // Good Range
      const grdi = yAxis.makeDataItem({
        value: this?.chartData?.goodrangefloor,
        endValue: this?.chartData?.goodrangeceiling,
      });
      const goodRange = series.createAxisRange(grdi);
      goodRange.strokes.template.setAll({
        stroke: this.ALIO_SUCCESS_GREEN,
        strokeWidth: 2,
      });

      // Low Range
      const lrdi = yAxis.makeDataItem({
        value: this?.chartData?.goodrangefloor,
        endValue: this?.chartData?.minrange,
      });
      const lowRange = series.createAxisRange(lrdi);
      lowRange.fills.template.setAll({
        fill: this.ALIO_SECONDARY_PURPLE,
        fillOpacity: 0.2,
        visible: true,
      });

      // High Range
      const hrdi = yAxis.makeDataItem({
        value: this?.chartData?.goodrangeceiling,
        endValue: this?.chartData?.maxrange,
      });
      const highRange = series.createAxisRange(hrdi);
      highRange.fills.template.setAll({
        fill: this.ALIO_SECONDARY_PURPLE,
        fillOpacity: 0.2,
        visible: true,
      });
    },
    setupAxisRanges(yAxis) {
      yAxis.setAll({
        baseValue: this.chartData?.goodrangefloor,
      });

      const rangeDataItem = yAxis.makeDataItem({
        value: this.chartData?.goodrangefloor,
        endValue: this.chartData?.goodrangeceiling,
      });
      const range = yAxis.createAxisRange(rangeDataItem);
      rangeDataItem.get("grid").set("visible", false);
      range.get("axisFill").setAll({
        fill: this.ALIO_RANGE_GREEN,
        fillOpacity: 1,
        visible: true,
      });

      const bottomDataItem = yAxis.makeDataItem({
        value: this.chartData?.goodrangefloor,
      });
      let rangeBottom = yAxis.createAxisRange(bottomDataItem);
      rangeBottom.get("grid").set("strokeOpacity", 0);
      rangeBottom.get("label").setAll({
        html: `<div class="threshold-tabs">
                    <svg width="4" height="24"><polygon stroke="none" fill="${this.ALIO_SUCCESS_GREEN_HEX}" points="4,6 0,12 4,18"/></svg>
                    <div class="threshold-text">${this.chartData?.goodrangefloor}</div>
                </div>`,
        paddingLeft: 0,
      });

      const topDataItem = yAxis.makeDataItem({
        value: this.chartData?.goodrangeceiling,
      });
      let rangeTop = yAxis.createAxisRange(topDataItem);
      rangeTop.get("grid").set("strokeOpacity", 0);
      rangeTop.get("label").setAll({
        html: `<div class="threshold-tabs">
                     <svg width="4" height="24"><polygon stroke="none" fill="${this.ALIO_SUCCESS_GREEN_HEX}" points="4,6 0,12 4,18"/></svg>
                     <div class="threshold-text">${this.chartData?.goodrangeceiling}</div>
                 </div>`,
        paddingLeft: 0,
      });

      if (
        this.chartData?.notificationlines &&
        this.chartData?.notificationlines?.length > 0
      ) {
        this.chartData?.notificationlines.map((nl) => {
          this.createGrid(yAxis, nl, true, true);
        });
      }

      this.createGrid(yAxis, this.chartData.minrange, true, false, true);
      this.createGrid(yAxis, this.chartData.maxrange, true, false, true);
    },
    setupSeries(chart, root, xAxis, yAxis) {
      const settings = {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: Tooltip.new(root, {
          labelText: this.getTooltipText(),
        }),
        showTooltipOn: "always",
      };

      settings.stroke = this.ALIO_PURPLE;
      settings.fill = this.ALIO_PURPLE;
      settings.connect = this.getLineConnect();
      settings.autoGapCount = this.getAutoGapCount(
        this.chartType,
        this.acute,
        this.acuteMode
      );

      const series = chart.series.push(LineSeries.new(root, settings));
      series.strokes.template.setAll({
        strokeWidth: 2,
      });
      return series;
    },
    setupYAxis(chart, root) {
      const yAxis = chart.yAxes.push(
        ValueAxis.new(root, {
          renderer: AxisRendererY.new(root, {
            opposite: true,
            minGridDistance: 20,
          }),
          strictMinMax: true,
          max: this.chartData.maxrange,
          min: this.chartData.minrange,
        })
      );

      const yRenderer = yAxis.get("renderer");
      yRenderer.setAll({
        minWidth: 35,
        opposite: true,
      });

      return yAxis;
    },
    getTooltipText() {
      return `{realValue.formatNumber('#,###.0')}${this.getUnitsString(
        this.unitsRangeDisplay
      )}`;
    },
    setupData() {
      return this.chartData.data.map((entry) => {
        const date = entry.x * 1000; // turn to ms
        const chartEntry = { date };

        switch (this.chartType) {
          case "hct":
            chartEntry.realValue = entry.y;
            if (entry.y > this.paddedMaxRange) {
              chartEntry.value = this.chartData.maxrange - this.padFactor;
            } else if (entry.y < this.paddedMinRange) {
              chartEntry.value = this.chartData.minrange + this.padFactor;
            } else {
              chartEntry.value = entry.y;
            }
            break;
          case "hgb":
            chartEntry.realValue = entry.y;
            if (entry.y > this.paddedMaxRange) {
              chartEntry.value = this.chartData.maxrange - this.padFactor;
            } else if (entry.y < this.paddedMinRange) {
              chartEntry.value = this.chartData.minrange + this.padFactor;
            } else {
              chartEntry.value = entry.y;
            }
            break;
          default:
            chartEntry.value = entry.y;
        }

        return chartEntry;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.threshold-tabs {
  height: 24px;
  width: 40px;
  display: flex;
  align-content: center;
  background-color: white;
  line-height: normal;
}
.threshold-text {
  height: 24px;
  width: 36px;
  border: 2px solid #1ab83d;
  color: #1ab83d;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  background-color: white;
}
.threshold-triangle {
  align-content: center;
}
</style>
