<template>
  <div ref="chartdiv" :class="chartClass"></div>
</template>

<script>
import {
  Root,
  Rectangle,
  Label,
  Bullet,
  Template,
  Container,
  p50,
} from "@amcharts/amcharts5";
import ChartMixin from "@/shared/mixins/ChartMixin";
import {
  AxisRendererY,
  LineSeries,
  ValueAxis,
  XYChart,
} from "@amcharts/amcharts5/xy";

export default {
  name: "AuscultationChart",
  mixins: [ChartMixin],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartId: {
      type: String,
      required: true,
    },
    acute: {
      type: Boolean,
      default: false,
    },
    acuteMode: {
      type: String,
      default: "noChange",
    },
    upperBound: {
      type: Number,
    },
    lowerBound: {
      type: Number,
    },
    chartType: {
      validator: function (value) {
        return ["auscultation"].indexOf(value) !== -1;
      },
      required: true,
    },
    clickHandler: {
      type: Function,
    },
  },
  data() {
    return {
      chartLoaded: false,
      label: null,
      unitsRange: null,
      unitsRangeDisplay: null,
    };
  },
  computed: {
    chartClass() {
      if (!this.chartLoaded) return "hidden-chart";
      return "auscultation-chart";
    },
  },

  watch: {
    chartData: function () {
      this.destroyRoot();
      this.createChart();
    },
  },
  mounted() {
    this.createChart();
  },

  beforeDestroy() {
    this.destroyRoot();
  },
  methods: {
    destroyRoot() {
      if (this.root) {
        this.root.dispose();
      }
    },
    createChart() {
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      this.root = Root.new(this.$refs.chartdiv);

      this.root.setThemes([this.getTheme(this.root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      const chart = this.root.container.children.push(
        XYChart.new(this.root, {
          maxTooltipDistance: 0,
          paddingRight: 42,
        })
      );
      chart.zoomOutButton.set("forceHidden", true);

      const yAxis = this.setupYAxis(chart, this.root);
      const xAxis = this.setupXAxis(
        chart,
        this.root,
        this.upperBound,
        this.lowerBound,
        {
          groupData: true,
          groupCount: 10,
          tooltip: null,
        }
      );

      const series = this.setupSeries(chart, this.root, xAxis, yAxis);

      this.setupBullets(this.root, series);

      series.data.setAll(this.setupData());

      series.appear(1000);
      chart.appear(1000, 100);

      this.chartLoaded = true;
    },
    setupBullets(root, series) {
      const bulletTemplate = Template.new(root, {});

      bulletTemplate.events.on("click", (ev) => {
        this.clickHandler(ev);
      });
      const bullet = series.bullets.push((root) => {
        const container = Container.new(
          root,
          {
            centerX: p50,
            centerY: p50,
          },
          bulletTemplate
        );

        const rectangle = Rectangle.new(root, {
          width: 30,
          height: 30,
          centerX: p50,
          centerY: p50,
          strokeWidth: 0,
          fill: this.ALIO_PURPLE,
        });

        container.children.push(rectangle);

        const label = Label.new(root, {
          text: "{valueY}",
          centerX: p50,
          centerY: p50,
          strokeWidth: 0,
          fill: this.WHITE,
          populateText: true,
        });

        container.children.push(label);

        return Bullet.new(root, {
          sprite: container,
        });
      });

      return bullet;
    },
    setupSeries(chart, root, xAxis, yAxis) {
      const settings = {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueYShow: "valueYTotalPercent",
        valueXField: "date",
        showTooltipOn: "never",
        stacked: true,
        groupDataCallback: function (dataItem, interval) {
          dataItem.set("valueY", dataItem.get("originals").length);
          dataItem.set("valueYWorking", dataItem.get("originals").length);
        },
      };

      settings.connect = this.getLineConnect();
      settings.stroke = this.ALIO_PURPLE;
      settings.fill = this.ALIO_PURPLE;

      const series = chart.series.push(LineSeries.new(root, settings));
      series.strokes.template.setAll({
        strokeWidth: 2,
        strokeOpacity: 0,
      });

      return series;
    },
    setupYAxis(chart, root) {
      const yAxis = chart.yAxes.push(
        ValueAxis.new(root, {
          renderer: AxisRendererY.new(root, {
            opposite: true,
            minGridDistance: 50,
          }),
          strictMinMax: true,
          max: 150,
          min: 50,
          calculateTotals: true,
        })
      );

      const yRenderer = yAxis.get("renderer");
      yRenderer.setAll({
        minWidth: 35,
        opposite: true,
      });
      yRenderer.labels.template.set("forceHidden", true);

      this.createGrid(yAxis, 50, true, false, true);
      this.createGrid(yAxis, 150, true, false, true);

      return yAxis;
    },
    setupData() {
      let value = 50;

      return this.chartData.data.map((entry) => {
        const date = entry.x * 1000; // turn to ms
        const chartEntry = { date };
        // chartEntry.url = entry.url;
        value -= Math.round(
          (Math.random() < 0.5 ? 1 : -1) * Math.random() * 10
        );
        if (value < 0) {
          value = Math.round(Math.random() * 10);
        }
        chartEntry.value = value;
        return chartEntry;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.auscultation-chart {
  flex: 1;
  height: 200px;
}
</style>
