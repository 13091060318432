<template>
  <b-modal :id="modalId" hide-header hide-footer @show="onShow">
    <b-tabs class="settings" justified>
      <b-tab :title="$t('clinicalActionModal.tabList')">
        <b-table
          class="basic-table"
          :items="clinical_actions"
          :fields="fields"
          :per-page="perPage"
        >
          <template #cell(created_on_epoch)="data">
            {{ $d(new Date(data.item.created_on_epoch * 1000), "table") }}
          </template>
        </b-table>
        <Pagination
          v-model="currentPage"
          :total-rows="total_rows"
          :per-page="perPage"
        ></Pagination>
      </b-tab>
      <b-tab
        v-if="isClinician($store.getters.user)"
        :title="$t('clinicalActionModal.tabAdd')"
      >
        <b-form @submit.prevent="submit">
          <b-form-group :label="$t('clinicalActionModal.notePrompt')">
            <b-form-textarea
              id="textarea"
              v-model="v$.notes.$model"
              :state="!v$.notes.$error"
              :placeholder="$t('clinicalActionModal.notePlaceholder')"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
          <button :disabled="isSubmitEnabled" type="submit">
            {{ $t("clinicalActionModal.submit") }}
          </button>
        </b-form>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Pagination from "@/shared/components/Primitives/Pagination.vue";

export default {
  components: { Pagination },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate({ $scope: false }),
    };
  },
  data: function () {
    return {
      currentPage: 1,
      perPage: 10,
      total_rows: 0,
      options: [
        { text: this.$t("clinicalActionModal.yes"), value: true },
        { text: this.$t("clinicalActionModal.no"), value: false },
      ],
      clinical_actions: [],
      fields: [
        {
          key: "added_by",
          label: this.$t("clinicalActionModal.clinicianHeader"),
        },
        {
          key: "created_on_epoch",
          label: this.$t("clinicalActionModal.dateHeader"),
        },
        { key: "notes", label: this.$t("clinicalActionModal.notesHeader") },
      ],
      notes: null,
    };
  },
  validations() {
    return {
      notes: {
        required,
      },
    };
  },
  computed: {
    isSubmitEnabled() {
      return this.v$.$invalid;
    },
    skip() {
      return (this.currentPage - 1) * this.perPage;
    },
  },
  watch: {
    currentPage() {
      this.fetchClinicalActions();
    },
  },
  methods: {
    fetchClinicalActions() {
      this.api
        .v1PatientPatientIdActionsGet(
          parseInt(this.$route.params.patientId, 10),
          this.perPage,
          this.skip
        )
        .then((response) => {
          this.clinical_actions = response.data?.clinical_actions?.map(
            (action) => {
              return {
                ...action,
                added_by: `${action.firstname} ${action.lastname}`,
              };
            }
          );
          this.total_rows = response.data?.total_rows;
        });
    },
    onShow: function () {
      this.notes = null;
      this.currentPage = 1;
      this.total_rows = 0;
      this.fetchClinicalActions();
    },
    submit() {
      this.$emit("add", {
        actionTaken: true,
        notes: this.notes,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  text-align: start;
}
</style>
