<template>
  <div>
    <div
      v-if="patient === null"
      id="patient-monitor-loading"
      class="data-loading-container"
    />
    <div v-else-if="patient" id="patient-controls">
      <header-container
        :header-text="$t('patient.detailsHeaderPatientInformation')"
      >
        <template #right>
          <div class="edit-icon-container">
            <b-button
              v-if="hasPhiAccess($store.getters.user)"
              variant="link"
              class="button-link"
              @click.prevent="goClinicalAction"
            >
              <img
                style="width: 16px"
                src="/icon-medical-notes.svg"
                alt="Medical Notes"
              />
            </b-button>
            <b-button
              variant="link"
              class="button-link"
              @click.prevent="goThresholdsPatient"
            >
              <b-icon-bell-fill></b-icon-bell-fill>
            </b-button>
            <b-button
              v-if="hasPhiAccess($store.getters.user)"
              variant="link"
              class="button-link"
              @click.prevent="goEditPatient"
            >
              <b-icon-pencil-fill></b-icon-pencil-fill>
            </b-button>
          </div>
        </template>
      </header-container>
      <info-item
        v-for="item in patientInformation"
        :key="item.label"
        :item="item"
      />
      <header-container :header-text="$t('patient.notificationsHeader')" />
      <info-item
        :item="{
          label: 'Notified Groups:',
          value: patient.notify_groups?.map((g) => g.name.trim())?.join(', '),
        }"
      />
      <info-item
        :item="{
          label: 'Notified Clinicians:',
          value: patient.notify_clinicians
            ?.map((c) => c.name.trim())
            ?.join(', '),
        }"
      />

      <div
        v-if="isSupportApp() && supportInfo !== null"
        class="patient-details-buttons-container"
        v-html="
          getSupportInfoLinkTag(
            supportInfo,
            'engineering_patient_reads_search',
            patient,
            'Engineering'
          )
        "
      />

      <div v-if="hasPhiAccess($store.getters.user)">
        <header-container :header-text="$t('patient.detailsHeaderAddresses')" />
        <div v-if="hasActiveAddress">
          <InfoItem
            :item="{
              label: $t('patient.labelDescription'),
              value: activeAddress.description,
            }"
          />
          <InfoItem
            :item="{
              label: $t('patient.labelLocalAddress'),
              value: activeAddress.street_city_state_address,
            }"
          />
          <InfoItem
            :item="{
              label: $t('patient.labelPostalCode'),
              value: activeAddress.postal_code,
            }"
          />
          <InfoItem
            :item="{
              label: $t('patient.labelCountryOrRegion'),
              value: activeAddress?.country?.name,
            }"
          />
        </div>
        <div v-else>{{ $t("patient.detailsAddressReminder") }}</div>
      </div>

      <div>
        <header-container
          :header-text="$t('patient.detailsHeaderMonitoringInformation')"
        />
        <InfoItem
          :item="{
            label: $t('adjustMonitoring.headerMonitoringStart'),
            value: monitoringStartDateFormatted,
          }"
        />
        <InfoItem
          :item="{
            label: $t('adjustMonitoring.headerMonitoringEnd'),
            value: monitoringEndDateFormatted,
          }"
        />
        <InfoItem
          :item="{
            label: $t('adjustMonitoring.headerMonitoringEndReason'),
            value: patient.monitoring_end_reason,
          }"
        />
        <div class="patient-details-buttons-container">
          <button
            id="stop-monitoring"
            type="button"
            class="button-link"
            :disabled="!stopMonitoringEnabled"
            @click.prevent="showStopMonitoringModal"
          >
            <span>{{ $t("patient.buttonStopMonitoring") }}</span>
          </button>
        </div>
      </div>

      <div v-if="isLogisticsSupported()">
        <header-container
          data-testid="patient-details-devices-header"
          :header-text="$t('patient.detailsHeaderDevices')"
          :button-text="$t('common.buttonAddPlusDevice')"
          @buttonClick="$bvModal.show('add-device-modal')"
        />
      </div>
      <div v-if="isClinicianApp()">
        <div class="assigned-devices-header">
          <sub-section-header :header-text="$t('patient.assigned')" />
          <sub-section-header :header-text="$t('patient.serialNumber')" />
        </div>
        <div class="device-section">
          <div
            v-for="(hub, index) in patient?.hubs"
            :key="hub.hub_id"
            class="device-list-row"
          >
            <sub-section-header :header-text="index === 0 ? 'Hub' : ''" />
            <span>{{ hub.hub_id }}</span>
          </div>
        </div>
        <div class="device-section">
          <div
            v-for="(patch, index) in patches"
            :key="patch.sn"
            class="device-list-row"
          >
            <sub-section-header :header-text="index === 0 ? 'Patch' : ''" />
            <span :class="[isActivePatch(patch, index) ? 'active-patch' : '']">
              {{ patch.sn }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="isSupportApp() && isLowerEnv">
        <header-container :header-text="$t('simulatorV2.title')" />
        <div class="patient-details-buttons-container">
          <button
            v-if="!simulatorPatient"
            type="button"
            class="button-link"
            @click.prevent="showDataSimulatorV2Modal"
          >
            <span>{{ $t("simulatorV2.start") }}</span>
          </button>
          <button
            v-if="simulatorPatient"
            type="button"
            class="button-link"
            @click.prevent="stopDataSimulatorV2"
          >
            <span>{{ $t("simulatorV2.stop") }}</span>
          </button>
        </div>
      </div>
      <div v-if="isSupportApp() && isLowerEnv">
        <header-container :header-text="$t('notificationsModal.title')" />
        <div class="patient-details-buttons-container">
          <button
            type="button"
            class="button-link"
            @click.prevent="showNotificationModal"
          >
            <span>{{ $t("notificationsModal.open") }}</span>
          </button>
        </div>
      </div>
    </div>
    <ClinicalActionModal
      modal-id="clinical-action-modal"
      @add="submitClinicalAction"
    />
    <SimulatorV2Modal
      v-if="isSupportApp()"
      modal-id="simulator-v2-modal"
      :simulator-patient="simulatorPatient"
      @create-or-update-simulator-v2="createOrUpdateSimulatorV2"
    />
    <NotificationsModal
      modal-id="notifications-modal"
      @send-notification="sendNotification"
    />
    <AddDevicesModal :patient-id="patient.id" @added="refresh" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Patch, Patient } from "@/shared/api-client/api";
import SimulatorV2Modal from "@/shared/components/SimulatorV2Modal.vue";
import NotificationsModal from "@/shared/components/NotificationsModal.vue";
import {
  getPatient,
  patchPatient,
  createPatient,
} from "@/shared/data-simulator-v2-api.js";
import { Clinic } from "@/shared/types/clinic";
import AddDevicesModal from "@/shared/components/AddDevicesModal.vue";
import { BIconPencilFill, BIconBellFill } from "bootstrap-vue";
import ClinicalActionModal from "@/shared/components/ClinicalActionModal.vue";
import HeaderContainer from "@/shared/components/Primitives/HeaderContainer.vue";
import InfoItem from "@/shared/components/Primitives/InfoItem.vue";
import SubSectionHeader from "@/shared/components/Primitives/SubSectionHeader.vue";

export default defineComponent({
  name: "PatientInfo",
  components: {
    SubSectionHeader,
    InfoItem,
    HeaderContainer,
    ClinicalActionModal,
    SimulatorV2Modal,
    NotificationsModal,
    AddDevicesModal,
    BIconPencilFill,
    BIconBellFill,
  },
  props: {
    patient: {
      type: Object as () => Patient,
      required: true,
    },
    clinic: {
      type: Object as () => Clinic,
      required: true,
    },
    clinicPatchLocations: {
      type: Array as () => any[],
      required: true,
    },
    supportInfo: {
      type: Object as () => any,
      default: () => ({}),
    },
    hubPatchData: {
      type: Array as () => any[],
      default: () => [],
    },
  },

  data() {
    return {
      simulatorPatient: null,
    };
  },
  computed: {
    patientIdParameter: function () {
      return parseInt(this.$route.params.patientId, 10);
    },
    clinicIdParameter: function () {
      return parseInt(this.$route.params.clinicId, 10);
    },
    activeAddress() {
      // if no addresses then nothing active
      if (!this.patient?.addresses?.length) return null;

      const activeAddress = this.patient?.addresses?.filter(
        (address) => address.active
      );
      if (activeAddress && activeAddress?.length) {
        return activeAddress[0];
      }
      return null;
    },
    hasActiveAddress() {
      // if no addresses then nothing active
      if (!this.patient?.addresses?.length) return false;

      const activeAddress = this.patient?.addresses?.filter(
        (address) => address.active
      );
      return !!(activeAddress && activeAddress?.length);
    },
    patches() {
      const patches = this.patient.patches
        .filter((patch: Patch) => {
          return ["active", "registered"].includes(patch?.status);
        })
        .sort((a, b) => {
          // active needs to be first
          // there should only be one active patch
          if (a.status === "active" && b.status !== "active") {
            return -1;
          } else if (a.status !== "active" && b.status === "active") {
            return 1;
          } else {
            return 0;
          }
        });
      if (patches.length > 10) {
        return patches.slice(0, 10);
      } else {
        return patches;
      }
    },
    stopMonitoringEnabled() {
      if (
        this.patient &&
        (this.patient.monitoring_end !== null ||
          this.patient.monitoring_start === null)
      ) {
        // Not enabled!
        return false;
      }

      return true;
    },
    monitoringStartDateFormatted() {
      const patient = this.patient;
      // If the monitoring_start is a number then it is valid
      if (patient && typeof patient.monitoring_start === "number") {
        return this.$d(new Date(patient.monitoring_start * 1000), "date");
      }

      return "";
    },
    monitoringEndDateFormatted() {
      const patient = this.patient;
      // If the monitoring_start is a number then it is valid
      if (patient && typeof patient.monitoring_end === "number") {
        return this.$d(new Date(patient.monitoring_end * 1000), "date");
      }

      return "";
    },
    patientInformation() {
      const info = [];

      if (this.patient) {
        info.push({
          label: this.$t("patient.detailsLabelName"),
          value: this.formatPatientName(this.patient),
        });
        info.push({
          label: this.$t("patient.detailsLabelDateOfBirth"),
          value: this.$d(
            this.createDateInLocalTz(this.getFieldPhi(this.patient, "dob")),
            "date"
          ),
        });
        info.push({
          label: this.$t("patient.detailsLabelEmail"),
          value: this.getFieldPhi(this.patient, "email"),
        });
        info.push({
          label: this.$t("patient.detailsLabelHomePhone"),
          value: this.getFieldPhi(this.patient, "homephone"),
        });
        info.push({
          label: this.$t("patient.detailsLabelMobilePhone"),
          value: this.getFieldPhi(this.patient, "mobilephone"),
        });
        info.push({
          label: this.$t("patient.detailsLabelPatientId"),
          value: this.getFieldPhi(this.patient, "clinicpatientidentifier"),
        });
        info.push({
          label: this.$t("patient.detailsLabelOrderingPhysician"),
          value: this.formatClinicUserName(this.patient.ordering_clinician),
        });
        info.push({
          label: this.$t("patient.detailsLabelPlacement"),
          value: this.patchPlacementFormatter(this.patient.patchplacement),
        });
        info.push({
          label: this.$t("patient.detailsLabelLastData"),
          value: this.patient.lastdataseen
            ? this.$d(new Date(this.patient.lastdataseen * 1000), "table")
            : null,
        });
      }
      return info;
    },
  },

  mounted() {
    this.getSimulatorPatient();
  },

  methods: {
    showActionToast() {
      this.$root.$bvToast.toast(this.$t("clinicalActionModal.actionSaved"), {
        title: this.$t("clinicalActionModal.actionSaved"),
        toaster: "b-toaster-bottom-right",
      });
    },
    goClinicalAction() {
      this.$bvModal.show("clinical-action-modal");
    },
    async submitClinicalAction(event) {
      try {
        await this.api.v1PatientPatientIdActionsPost(this.patientIdParameter, {
          action_taken: event.actionTaken,
          notes: event.notes,
          clinic_id: this.clinicIdParameter,
        });
        this.showActionToast();
      } catch (error) {
        this.showApiResponseError(
          error,
          this.$t("patient.errorClinicalActionFeedback")
        );
      }
      this.$bvModal.hide("clinical-action-modal");
    },
    isActivePatch(patch, index) {
      // the first patch may not be active. Bold is meant to indicate the active patch
      if (index !== 0) return false;
      return patch.status === "active";
    },
    refresh() {
      this.$emit("refresh");
    },
    getSimulatorPatient() {
      if (this.isLowerEnv) {
        getPatient({ id: this.patient.id })
          .then((results) => {
            this.simulatorPatient = results.data;
          })
          .catch(() => {
            // do nothing
          });
      }
    },
    async sendNotification(args) {
      try {
        await this.api.v1NotificationsPost({
          patient_id: this.patient.id,
          ...args,
        });
      } catch (error) {
        this.showApiResponseError(
          error,
          this.$t("notificationsModal.failedToSend")
        );
      }
    },
    async createOrUpdateSimulatorV2(args) {
      console.log("starting simulator");
      const { acute_patient_mode_id, scenario_id } = args;
      try {
        if (this.simulatorPatient) {
          await patchPatient({
            id: this.patient.id,
            scenario_id,
            acute_patient_mode_id,
          });
        } else {
          const start_time = this.patient.monitoring_start
            ? new Date(this.patient.monitoring_start * 1000)
            : new Date(Date.now());
          const end_time = this.patient.monitoring_start
            ? new Date(
                (this.patient.monitoring_start + 60 * 60 * 24 * 28) * 1000
              )
            : new Date(Date.now() + 60 * 60 * 24 * 28 * 1000);
          await createPatient({
            id: this.patient.id,
            scenario_id,
            acute_patient_mode_id,
            start_time: start_time.toISOString(),
            end_time: end_time.toISOString(),
          });
          this.getSimulatorPatient();
        }
      } catch (error) {
        console.error(error);
      }
    },
    stopDataSimulatorV2() {
      patchPatient({ id: this.patient.id, is_active: false })
        .then(() => {
          this.simulatorPatient = null;
          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t("simulatorV2.stopped")
          );
        })
        .catch((error) => {
          this.showApiResponseError(error, this.$t("simulatorV2.failedToStop"));
        });
    },
    goEditPatient() {
      this.$router.push({
        name: "edit-patient",
        params: {
          clinicId: this.$route.params.clinicId,
          patientId: this.$route.params.patientId,
        },
      });
    },
    goThresholdsPatient() {
      this.$router.push({
        name: "thresholds-patient",
        params: {
          clinicId: this.$route.params.clinicId,
          patientId: this.$route.params.patientId,
        },
      });
    },
    patchPlacementFormatter(patchPlacement) {
      const patchLocations = this.clinicPatchLocations;

      if (patchLocations) {
        for (let item of patchLocations) {
          if (item.id === patchPlacement) {
            return item.locationname;
          }
        }
      }

      return "";
    },
    hasMonitoringCompleted() {
      if (this.patient.monitoring_end === null) {
        return false;
      } else {
        return true;
      }
    },
    showStopMonitoringModal() {
      this.$bvModal.show("stop-monitoring-modal");
    },
    showDataSimulatorV2Modal() {
      this.$bvModal.show("simulator-v2-modal");
    },
    showNotificationModal() {
      this.$bvModal.show("notifications-modal");
    },
    isLogisticsSupported() {
      if (this.isSupportApp() || this.hasLogisticsFeatureSupport(this.clinic)) {
        return true;
      }

      return false;
    },
  },
});
</script>

<style lang="scss" scoped>
.patient-details-buttons-container {
  margin-top: 16px;
  flex-wrap: nowrap;
  justify-content: space-between;

  > *:not(:first-child) {
    margin-left: 14px;
  }

  button {
    min-width: 30px;
    max-width: 200px;
  }
}

.assigned-devices-header {
  display: flex;
  justify-content: space-between;
  border-bottom: $gray-dark solid 1px;
  color: $gray-dark;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 8px;
}
.device-list-row {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}
.active-patch {
  font-weight: bold;
}
.device-header-type {
  font-weight: bold;
}
.device-section {
  margin-top: 8px;
}
.edit-icon-container {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
</style>
