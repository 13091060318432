<template>
  <div class="chart-header-container">
    <div :id="`chart-header-availability`" class="chart-header">
      <section-header
        id="chart-header-label-availability"
        :header-text="label"
      />
    </div>
    <div class="zoom-buttons-container">
      <button
        v-b-tooltip.hover
        title="Download CSV for Date Window"
        class="zoom-button"
        @click="download"
      >
        <SVGIconWrapper class="zoom-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="size-6"
          >
            <path
              fill-rule="evenodd"
              d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z"
              clip-rule="evenodd"
            />
          </svg>
        </SVGIconWrapper>
      </button>
      <RangeDatePicker
        v-if="selectedDate"
        id="range-date-picker"
        v-model="selectedDate"
        :monitoring-start="value.monitoringStart"
        :monitoring-end="value.monitoringEnd"
      />
    </div>
  </div>
</template>
<script>
import { DataWindow } from "@/shared/utils/dataWindow";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import RangeDatePicker from "@/shared/components/Inputs/RangeDatePicker/RangeDatePicker.vue";
import SVGIconWrapper from "@/shared/components/Primitives/SVGIconWrapper.vue";

export default {
  components: {
    SVGIconWrapper,
    SectionHeader,
    RangeDatePicker,
  },
  props: {
    value: {
      type: DataWindow,
      default: null,
    },
  },
  data() {
    return {
      label: null,
    };
  },
  computed: {
    selectedDate: {
      get() {
        if (this.value) {
          return [this.value.windowStartDate, this.value.windowEndDate];
        }
        return null;
      },
      set(newValue) {
        // newValue is the new value passed to the computed property
        // You can perform your logic here
        // For example, if newValue is an array of two dates:
        if (Array.isArray(newValue) && newValue.length === 2) {
          this.value.setWindowStart(newValue[0]);
          this.value.setWindowEnd(newValue[1]);
          this.$emit(
            "input",
            DataWindow.fromExportedState(this.value.exportState())
          );
        }
      },
    },
  },
  mounted() {
    this.setHeaderTitle("patient.detailsHeaderAvailability");
  },
  methods: {
    setHeaderTitle(translateKey) {
      this.label = this.$t(translateKey);
    },
    download() {
      this.$emit("download");
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-header-value {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
}
.chart-header-units {
  font-size: 16px;
  font-weight: 600;
}
.chart-header-units-margin {
  margin-left: 4px;
  font-size: 16px;
  font-weight: 600;
}
.windowing-button {
  width: auto;
  min-width: initial;
  max-width: 100%;
  padding: 4px 8px;
  &.dates {
    width: 200px;
  }
}
.windowing-button:disabled {
  background-color: #ebeeef;
  font-weight: normal;
  padding: 4px 8px;
}
.zoom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  max-width: 32px;
  padding: 4px 4px 12px;
}
.zoom-buttons-container {
  display: flex;
  padding-right: 32px;
  align-items: center;
  gap: 16px;

  button {
    margin-left: 8px;
    height: 32px;
    font-size: 18px;
  }
}

.chart-header-row {
  width: 100%;
}
.chart-header-label {
  margin-right: 16px;
  text-transform: uppercase;
}
.chart-header {
  color: $gray-dark;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  line-height: normal;
}
.chart-header-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
